import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import 'rxjs/add/operator/takeWhile';
import * as _moment from 'moment';

/**
 * Generated class for the CepComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'ngx-datepicker',
  templateUrl: 'datepicker.html',
})
export class DatepickerComponent extends FieldType {
  on = true;
  hasMinRel = false;
  hasMaxRel = false;

  ngOnInit() {
    this.parseDateRel();
    if (this.to.refresh) {
      this.to.refresh.forEach(control => {
        let formControl = this.form.get(control);
        if (formControl) {
          formControl.valueChanges
            .takeWhile(() => this.on)
            .debounceTime(500)
            .subscribe(() => {
              this.parseDateRel();
            });
        }
      });
    }
  }
  parseDateRel() {
    if (this.to.minDateRel) {
      this.to.minDate = _moment().add(
        this.to.minDateRel.ammount,
        this.to.minDateRel.unit,
      );
      this.hasMinRel = true;
    } else if (this.hasMinRel) {
      this.to.minDate = null;
    }
    if (this.to.maxDateRel) {
      this.to.maxDate = _moment().add(
        this.to.maxDateRel.ammount,
        this.to.maxDateRel.unit,
      );
      this.hasMaxRel = true;
    } else if (this.hasMaxRel) {
      this.to.minDate = null;
    }
  }
  ngOnDestroy() {
    this.on = false;
  }
}
