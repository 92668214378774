import * as Slug from 'speakingurl';

export const Parsers = {
  cpf(value) {
    return value ? value.replace(/(\.|\/|\-|_)/g, '') : null;
  },
  cnpj(value) {
    return value ? value.replace(/(\.|\/|\-|_)/g, '') : null;
  },
  slug(value) {
    return Slug(value, { separator: '_' });
  },
  _value(value) {
    if (value) {
      return value._value;
    }
    return null;
  },
  phone(value) {
    return value ? value.replace(/(\W)/g, '') : null;
  },
  cep(value) {
    return value ? value.replace(/\-/g, '') : null;
  }
};
