import { parseText } from "../../helpers";

export const defaultFormActionsTypes = {
  update: (action: any, model: any) => {
    action.type = 'post';
    action.method = action.method || 'put';
    action.toast = true;
    action.redirect = action.redirect || false;
    action.params = action.params || Object.assign({ model }, action.params);
    action.disableNotValid = true;
    action.class = 'btn btn-hero-success';
    action.button_type = 'submit';
  },
  create: (action: any, model: any) => {
    action.type = 'post';
    action.method = action.method || 'add';
    action.toast = true;
    action.redirect = action.redirect || 'back';
    action.params = action.params || Object.assign({ model }, action.params);
    action.disableNotValid = true;
    action.class = 'btn btn-hero-success';
    action.button_type = 'submit';
  },
  cancel(action: any, model: any) {
    action.type = 'cancel';
    action.redirect = action.redirect || 'back';
    action.disableNotValid = false;
    action.class = 'btn btn-hero-secondary ml-auto';
    action.button_type = 'button';
  },
  link(action: any, model: any) {
    action.link = parseText(action.link, model);
    action.button_type = 'button';
  },
};
