import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ToasterService, Toast } from 'angular2-toaster';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { BaseResourceProvider } from '../../../@core/data/providers/base-resource-provider';
import { Subject } from 'rxjs';
import { parseText, parseActionParams } from '../../../helpers';

@Component({
  selector: 'formly-scene',
  templateUrl: './formly-scene.component.html',
  styleUrls: ['./formly-scene.component.scss'],
})
export class FormlySceneComponent implements OnInit {
  loading: boolean = false;
  formSubject = new Subject();

  @Input()
  options: FormlyFormOptions = {
    formState: {},
  };

  @Input() model: any = {};
  @Input() fields: string | Array<FormlyFieldConfig> = [];
  @Input() provider: BaseResourceProvider;

  @Input()
  toastSuccess: Toast = {
    type: 'success',
    title: 'Registro salvo com sucesso!',
    showCloseButton: true,
  };

  @Input()
  toastError: Toast = {
    type: 'error',
    title: 'Erro ao salvar registro!',
    showCloseButton: true,
  };

  @Input() routeParams: any;

  @Input()
  loadActions: Array<{
    type: string; // link, post, cancel, reset
    method?;
    toast?;
    redirect?;
    redirectTo?;
    params?;
    permission?;
    wrap_params?;
    changes?;
  }> = [];

  form: FormGroup;

  constructor(
    private toasterService: ToasterService,
    private location: Location,
    private router: Router,
  ) {
    this.form = new FormGroup({});
  }

  ngOnInit() {
    this.options.formState.scene = this;
    this.options.formState.formSubject = this.formSubject;

    this.onLoad();
  }

  setLoading(loading: boolean = true) {
    this.loading = loading;
  }

  doToast(toast: Toast) {
    this.toasterService.popAsync(toast);
  }

  doRedirect(to: string) {
    if (to) {
      switch (to) {
        case 'back':
          this.location.back();
          break;
        default:
          this.router.navigate([to]);
          break;
      }
    }
  }
  success(toast, redirect, response) {
    this.setLoading(false);
    if (toast) this.doToast(this.toastSuccess);

    if (redirect) {
      redirect = typeof response === 'object' ? parseText(redirect, response) : redirect;
      this.doRedirect(redirect);
    }

    this.formSubject.next('success');
  }

  error(toast) {
    this.setLoading(false);
    if (toast) this.doToast(this.toastError);
    this.formSubject.next('error');
  }

  post(action) {
    this.setLoading();

    this.provider[action.method]
      .apply(this.provider, Object.values(action.params))
      .subscribe(
        response => {
          this.success(action.toast, action.redirect, response);
        },
        () => {
          this.error(action.toast);
        },
      );
  }

  cancel(action) {
    this.formSubject.next('cancel');
    if (action.redirect) this.doRedirect(action.redirect);
  }

  reset() {
    this.formSubject.next('reset');
    this.options.resetModel();
  }

  post_observable(action) {
    this.setLoading();

    return this.provider[action.method]
      .apply(this.provider, Object.values(action.params));
  }

  onLoad() {
    const length = this.loadActions.length;
    let count = 0;

    if (length) {
      this.loadActions.forEach(action => {
        if (action.params === 'model' || action.params === 'route') {
          const paramMap = {
            model: this.model,
            route: this.routeParams,
          }

          action.params = action.wrap_params ? { "0": paramMap[action.params] } : paramMap[action.params];
        } else {
          action.params = parseActionParams((<{ params: any, wrap_params?: boolean }>action), this.model);
        }
      });

      this.post_observable(this.loadActions[count])
        .subscribe(
          () => {
            count++;

            if (count <= length - 1) {
              this.post_observable(this.loadActions[count]);
            } else {
              this.setLoading(false);
            }
          },
          () => {
            this.setLoading(false);
          }
        );
    }
  }
}
