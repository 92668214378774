import { config } from './../../../helpers';
import { Component, Input, OnInit } from '@angular/core';
import { PanelProvider } from '../../../@core/data/providers/panel/panel';

@Component({
  selector: 'ngx-ext-header',
  styleUrls: ['./ext-header.component.scss'],
  templateUrl: './ext-header.component.html',
})
export class ExtHeaderComponent implements OnInit {
  @Input() position = 'normal';

  title: string;
  logo: string;

  constructor(public panelProvider: PanelProvider) {
    this.title = this.panelProvider.config('core.title');
    this.logo = config('core.logosm');
  }

  ngOnInit() {}
}
