import { langc, config } from './../../../../helpers';
import { FormGroup, FormArray } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { lang } from '../../../../helpers';
export function TemplateOptionsTransformer(
  fields: FormlyFieldConfig[],
  model: any,
  form: FormGroup | FormArray,
  options: FormlyFormOptions,
): FormlyFieldConfig[] {
  fields.map(element => {
    let obj: any = element;
    if (obj.to) {
      obj.templateOptions = obj.to;
    }
    langLabel(element, model);
    className(element, options);
    bootstrapWrappers(element);
    if (element.fieldGroup) {
      if (typeof element.fieldGroup === 'string') {
        element.fieldGroup = JSON.parse(
          JSON.stringify(config(element.fieldGroup)),
        );
      }
      element.fieldGroupClassName = 'row';
      element.fieldGroup = TemplateOptionsTransformer(
        element.fieldGroup,
        model,
        form,
        options,
      );
    }
  });

  return fields;
}

function className(obj, options) {
  if (options.formState.className && !obj.className) {
    obj.className = options.formState.className;
  }
}

const excludedFiledsBootstrapWrappers = ['text', 'table', 'search'];
function bootstrapWrappers(field: FormlyFieldConfig) {
  if (
    !field.key ||
    excludedFiledsBootstrapWrappers.indexOf(field.type) !== -1
  ) {
    field.templateOptions = field.templateOptions || {};
    field.templateOptions.descriptionWrapper = false;
    field.templateOptions.validationWrapper = false;
  }
}

function langLabel(obj, model) {
  if (obj.templateOptions && obj.templateOptions.label) {
    if (typeof obj.templateOptions.label === 'string') {
      obj.templateOptions.label = lang(obj.templateOptions.label, model);
    } else {
      obj.templateOptions.label = langc(
        obj.templateOptions.label[0],
        obj.templateOptions.label[1],
        model,
      );
    }
  }
}
