import { LogoutComponent } from './@theme/components/auth/logout/logout.component';
import { AuthComponent } from './@theme/components/auth/auth.component';
import { LoginComponent } from './@theme/components/auth/login/login.component';
import { AuthGuard } from './auth-guard.service';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
} from '@nebular/auth';
import { AuthGuardPermissions } from './auth-guard-permissions.service';
import { McBreadcrumbsModule } from 'ngx-breadcrumbs';

export const routes: Routes = [
  {
    path: 'p',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuardPermissions],
    loadChildren: 'app/pages/pages.module#PagesModule',
    data: { permissionsComponent: 'pdj' },
  },
  {
    path: 'e',
    canActivateChild: [AuthGuardPermissions],
    loadChildren: 'app/external-pages/external-pages.module#ExternalPagesModule',
    data: { permissionsComponent: 'pdj' },
  },
  {
    path: 'b',
    canActivateChild: [AuthGuardPermissions],
    loadChildren: 'app/embed/embed.module#EmbedPagesModule',
    data: { permissionsComponent: 'pdj' },
  },
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      {
        path: '',
        component: LoginComponent,
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'logout',
        component: LogoutComponent,
      },
    ],
  },
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },
];

const config: ExtraOptions = {
  useHash: true,
  enableTracing: false, // <-- debugging purposes only
};

@NgModule({
  imports: [
    RouterModule.forRoot(routes, config),
    McBreadcrumbsModule.forRoot(),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
