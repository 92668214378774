import { FilePond } from 'angular-filepond';
import { Component, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/takeWhile';
import { environment } from '../../../../../environments/environment';
import { toBase64String } from '@angular/compiler/src/output/source_map';

/**
 * Generated class for the CepComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'ngx-filepond',
  templateUrl: 'filepond.html',
})
export class FilePondComponent extends FieldType {
  on = true;
  @ViewChild('filePond') filePond: FilePond;
  files = [];
  attachments: Array<any> | Object;
  server: any;

  // Chekc for mime tipes https://www.freeformatter.com/mime-types-list.html
  acceptedFileTypes = [];

  /*
  |--------------------------------------------------------------------------
  | Labels
  |--------------------------------------------------------------------------
  */
  labelDecimalSeparator: string;
  labelThousandsSeparator: string;
  labelIdle: string;
  labelFileWaitingForSize: string;
  labelFileSizeNotAvailable: string;
  labelFileLoading: string;
  labelFileLoadError: string;
  labelFileProcessing: string;
  labelFileProcessingComplete: string;
  labelFileProcessingAborted: string;
  labelFileProcessingError: string;
  labelTapToCancel: string;
  labelTapToRetry: string;
  labelTapToUndo: string;
  labelButtonRemoveItem: string;
  labelButtonAbortItemLoad: string;
  labelButtonRetryItemLoad: string;
  labelButtonAbortItemProcessing: string;
  labelButtonUndoItemProcessing: string;
  labelButtonRetryItemProcessing: string;
  labelButtonProcessItem: string;

  /*
  |--------------------------------------------------------------------------
  | Configs
  |--------------------------------------------------------------------------
  */
  className: any;
  required: any;
  captureMethod: any;
  allowDrop: any;
  allowBrowse: any;
  allowPaste: any;
  allowMultiple: any;
  allowReplace: any;
  allowRevert: any;
  maxFiles: any;
  maxFileSize: any;

  ngOnInit() {
    this.defauts();
    this.fillFiles();
    const resource = this.field.templateOptions.resource;
    this.server = {
      process: this.process(),
      load: this.load(),
      revert: this.revert(),
    };

    setTimeout(() => {
      this.handleFilePondInit();
    }, 500);
  }

  revert() {
    const resource = this.field.templateOptions.resource;
    if (!this.model.id) {
      return `${environment.api.url}/${resource}/attach/${this.field.key}/${
        this.model.id
        }`;
    }
    return null;
  }
  process() {
    const resource = this.field.templateOptions.resource;
    let url = null;
    url = `${environment.api.url}/${resource}/attach/${this.field.key}`;
    return {
      url: url,
      headers: this.authHeaders(),
    };
  }
  load() {
    const resource = this.field.templateOptions.resource;
    if (this.model.id) {
      return {
        url: `${environment.api.url}/${resource}/${this.model.id}/attach/`,
        headers: this.authHeaders(),
      };
    }
    return null;
  }
  authHeaders() {
    return {
      Authorization: 'Bearer ' + localStorage.getItem(environment.token),
    };
  }

  defauts() {
    this.labelDecimalSeparator = this.to.labelDecimalSeparator || 'auto';
    this.labelThousandsSeparator = this.to.labelThousandsSeparator || 'auto';
    this.labelIdle =
      this.to.labelIdle ||
      'Arraste e solte o(s) arquivo(s) ou <span class="filepond--label-action"> Explore </span>';
    this.labelFileWaitingForSize =
      this.to.labelFileWaitingForSize || 'Aguardando tamanho';
    this.labelFileSizeNotAvailable =
      this.to.labelFileSizeNotAvailable || 'Tamanho não disponível';
    this.labelFileLoading = this.to.labelFileLoading || 'Carregando';
    this.labelFileLoadError =
      this.to.labelFileLoadError || 'Erro durante o carregamento';
    this.labelFileProcessing = this.to.labelFileProcessing || 'Enviando';
    this.labelFileProcessingComplete =
      this.to.labelFileProcessingComplete || 'Envio completo';
    this.labelFileProcessingAborted =
      this.to.labelFileProcessingAborted || 'Envio cancelado';
    this.labelFileProcessingError =
      this.to.labelFileProcessingError || 'Erro durante o envio';
    this.labelTapToCancel = this.to.labelTapToCancel || 'Clique para cancelar';
    this.labelTapToRetry =
      this.to.labelTapToRetry || 'Clique para tentar novamente';
    this.labelTapToUndo = this.to.labelTapToUndo || 'Clique para desfazer';
    this.labelButtonRemoveItem = this.to.labelButtonRemoveItem || 'Excluir';
    this.labelButtonAbortItemLoad = this.to.labelButtonAbortItemLoad || 'Parar';
    this.labelButtonRetryItemLoad =
      this.to.labelButtonRetryItemLoad || 'Tentar novamente';
    this.labelButtonAbortItemProcessing =
      this.to.labelButtonAbortItemProcessing || 'Cancelar';
    this.labelButtonUndoItemProcessing =
      this.to.labelButtonUndoItemProcessing || 'Desfazer';
    this.labelButtonRetryItemProcessing =
      this.to.labelButtonRetryItemProcessing || 'Tentar novamente';
    this.labelButtonProcessItem = this.to.labelButtonProcessItem || 'Enviar';

    this.className = this.to.hasOwnProperty('className') ? this.to.className : null;
    this.required = this.to.hasOwnProperty('required') ? this.to.required : false;
    this.captureMethod = this.to.hasOwnProperty('captureMethod') ? this.to.captureMethod : null;
    this.allowDrop = this.to.hasOwnProperty('allowDrop') ? this.to.allowDrop : true;
    this.allowBrowse = this.to.hasOwnProperty('allowBrowse') ? this.to.allowBrowse : true;
    this.allowPaste = this.to.hasOwnProperty('allowPaste') ? this.to.allowPaste : true;
    this.allowMultiple = this.to.hasOwnProperty('allowMultiple') ? this.to.allowMultiple : false;
    this.allowReplace = this.to.hasOwnProperty('allowReplace') ? this.to.allowReplace : false;
    this.allowRevert = this.to.hasOwnProperty('allowRevert') ? this.to.allowRevert : true;
    this.maxFiles = this.to.hasOwnProperty('maxFiles') ? this.to.maxFiles : null;
    this.acceptedFileTypes = this.to.hasOwnProperty('acceptedFileTypes') ? this.to.acceptedFileTypes : [];
    this.maxFileSize = this.to.hasOwnProperty('maxFileSize') ? this.to.maxFileSize : null;
  }

  fillFiles() {
    if (this.allowMultiple) {
      if (this.model[this.field.key] && Array.isArray(this.model[this.field.key])) {
        this.model[this.field.key].forEach(item => {
          this.files.push({
            source: item.key,
            shared: item.shared,
            filename: item.filename,
            options: {
              type: 'local',
            },
          });
        });
      }
    } else {
      if (this.model[this.field.key]) {
        const item = this.model[this.field.key];
        this.files.push({
          source: item.key,
          shared: item.shared,
          filename: item.filename,
          options: {
            type: 'local',
          },
        });
      }
    }
  }

  ngOnDestroy() {
    this.on = false;
  }

  handleFilePondInit() {
    const pond = document.querySelector('#' + this.field.key + '.filepond--root');
    if (pond) {
      pond.addEventListener('FilePond:addfile', (e: any) => {
        this.activateDownloadLink(e);
        this.setFormControl(e);
      });
      pond.addEventListener('FilePond:processfile', (e: any) => {
        this.activateDownloadLink(e);
        this.setFormControl(e);
      });
      pond.addEventListener('FilePond:processfileundo', (e: any) => {
        this.deleteServerAttachment(e);
        this.setFormControl(e);
      });
      pond.addEventListener('FilePond:removefile', (e: any) => {
        this.setFormControl(e);
      });
    }

    // FilePond instance methods are available on `this.myPond`
  };

  setFormControl(e) {
    const files = e.detail.pond.getFiles();
    let value = null;
    if (this.allowMultiple) {
      value = {
        data: [],
      };
      if (files) {
        files.forEach(file => {
          if (file.serverId)
            value.data.push({
              key: file.serverId,
            });
        });
      }
    } else {
      value = {};
      if (files.length > 0 && files[0].serverId) {
        value = {
          key: files[0].serverId,
        };
      }
    }
    this.formControl.setValue(value);
  }

  deleteServerAttachment(e) { }

  activateDownloadLink(e) {
    setTimeout(() => {
      const file = this.fileBySource(e.detail.file.serverId);
      const element = document.querySelector(
        '#' +
        this.field.key +
        ' input[value="' +
        e.detail.file.serverId +
        '"] ~ .filepond--file > .filepond--file-info',
      );
      if (element && file) {
        element.innerHTML =
          '<a  href="' + file.shared + '">' + element.innerHTML + '</a>';
      }
    }, 200);
  }

  fileBySource(source) {
    let file = null;
    this.files.forEach(item => {
      if (item.source === source) {
        file = item;
      }
    });
    return file;
  }
}
