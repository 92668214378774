import { __nested, pluck, config } from './../../../../helpers';
import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'ngx-analytics',
  templateUrl: 'analytics.component.html',
})
export class AnalyticsComponent extends FieldType {
  source: any[] = [];
  values: any[] = [];

  chart: any = {
    scheme: 'cool',
    type: 'bar-vertical',
    xAxis: true,
    yAxis: true,
    showXAxisLabel: true,
    showYAxisLabel: true,
    legend: true,
    legendTitle: 'Legenda',
    showDataLabel: true
    //xAxisLabel:''
    //yAxisLabel:''
  };

  on: boolean = true;
  ngOnInit() {
    this.loadValues();

    this.chart = this.to.chart
      ? Object.assign(this.chart, this.to.chart)
      : this.chart;
  }

  onSelect(event) {
    //console.log(event);
  }

  applyGroupBy() {
    if (this.values) {
      this.values = this.values.reduce((acc, item, index) => {
        var key = this.getIndexGroupBy(item, index);
        acc[key] = acc[key] || [];
        acc[key].push(item);
        return acc;
      }, {});
      this.values = Object.values(this.values);
    }
  }

  getIndexGroupBy(item: any, index) {
    if (this.to.groupBy) {
      index = '';
      this.to.groupBy.forEach(element => {
        index += __nested(item, element);
      });
      return index;
    }
    return index;
  }
  applyWhere() {
    if (this.values && this.to.where) {
      this.values = this.source.filter(item => eval(this.to.where));
    }
  }
  applyAggr() {
    if (this.values) {
      for (let aggr of this.to.aggr) {
        this.values = this.values.map((items, i) => {
          let ag = items.reduce((acc, item, index) => {
            acc = acc || item;
            eval(aggr.function);
            return acc;
          }, false);
          return ag;
        });
      }
    }
  }

  applyPluck() {
    if (this.to.pluck) {
      if (this.values) {
        this.values = this.values.map(el => {
          return pluck(el, this.to.pluck);
        });
      }
    }
  }

  changeValues() {
    this.values = this.source;
    this.applyWhere();
    this.applyGroupBy();
    this.applyAggr();
    this.applyPluck();
  }

  loadValues() {
    this.source = this.form.get(this.to.sourceField).value;
    this.changeValues();
    this.form
      .get(this.to.sourceField)
      .valueChanges.debounceTime(500)
      .takeWhile(() => this.on)
      .subscribe(() => {
        this.source = this.form.get(this.to.sourceField).value;
        this.changeValues();
      });
  }

  ngOnDestroy() {
    this.on = false;
  }
}
