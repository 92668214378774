import { FieldType } from '@ngx-formly/core';
import { Component, Input } from '@angular/core';
import { Providers } from '../../../data/providers/providers';
import { ToasterService, ToasterConfig, Toast } from 'angular2-toaster';
import { BaseResourceProvider } from '../../../data/providers/base-resource-provider';
import { AuthProvider } from '../../../data/providers/auth/auth';
import { user } from '../../../../helpers';

@Component({
  selector: 'ngx-comment',
  templateUrl: './comment.html',
  styleUrls: ['./comment.scss'],
})
export class CommentComponent extends FieldType {
  loading = true;
  commentMode = 'view';
  comenting = false;
  comments: Array<any>;
  perm_target: string;
  provider;

  @Input() showToast = true;

  toasterConfig = new ToasterConfig({
    positionClass: 'toast-top-right',
    timeout: 15000,
    animation: 'slideDown',
    limit: 1,
  });

  toastError: Toast = {
    type: 'error',
    title: 'Erro ao salvar comentário!',
    showCloseButton: true,
  };

  constructor(private toasterService: ToasterService, private auth: AuthProvider) {
    super();
  }

  ngOnInit() {
    this.provider = Providers.get(this.field.templateOptions.resource);
    this.perm_target = this.provider.getResource() + '_comments';
    this.loadComments();
  }

  doToast(toast: Toast) {
    if (this.showToast) {
      this.toasterService.popAsync(toast);
    }
  }

  loadComments() {
    this.loading = true;
    this.provider.get(this.model.id, 'comments').subscribe((response) => {
      this.comments = response.comments.data;
      this.loading = false;
    });
  }

  toComment(commentBody) {
    this.loading = true;
    this.comenting = false;
    const body = commentBody.value;

    this.provider.comment({
      id: this.model.id,
      title: null,
      body: body,
    })
      .then((response) => {
        this.comments.push(response);
        commentBody.value = '';
        this.loading = false;
      })
      .catch((response) => {
        this.doToast(this.toastError);
        this.loading = false;
      });

  }

  deleteComment(commentId, commentIndex) {
    this.loading = true;
    this.provider.destroyComment({
      id: this.model.id,
      commentId: commentId,
    })
      .then(() => {
        this.comments.splice(commentIndex, 1);
        this.loading = false;
      })
      .catch((response) => {
        this.doToast(this.toastError);
        this.loading = false;
      });
  }

  updateComment(commentId, commentBody, commentIndex) {
    this.loading = true;
    this.provider.patchComment({
      id: this.model.id,
      commentId: commentId,
      title: null,
      body: commentBody.value,
    }).subscribe(
      (response) => {
        this.comments[commentIndex] = response;
        this.loading = false;
      },
      (response) => {
        this.doToast(this.toastError);
        this.loading = false;
      },
    )

    this.commentMode = 'view';
  }

  isCreator(creator) {
    return creator.id === this.auth.user().id;
  }
}
