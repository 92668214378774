import { FieldType } from '@ngx-formly/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-search',
  templateUrl: './search.component.html',
})
export class SearchComponent extends FieldType {
  ngOnInit() {}
}
