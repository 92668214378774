import { ImportField } from '../fields/import/import-field';
import { FilePondComponent } from '../fields/filepond/filepond';
import { RepeatTypeComponent } from './../fields/repeat/repeat';
import { TableComponent } from '../fields/table/table.component';
import { SelectTableComponent } from '../fields/select-table/select-table.component';
import { MultiSelectComponent } from '../fields/multi-select/multi-select.component';

export const COMPLEX_TYPES = [
  
  /* Table */
  {
    name: 'table',
    wrappers: null,
    component: TableComponent,
  },
  /* Repeat */
  {
    name: 'repeat',
    wrappers: null,
    component: RepeatTypeComponent,
    defaulOptions: {
      fieldArray: {
        fieldGroupClassName: 'col-6',
      },
    },
  },
  /* FilePond */
  {
    name: 'filepond',
    wrappers: ['fieldset', 'label'],
    component: FilePondComponent,
    defaultOptions: {
      templateOptions: {
        label: 'Adicione os arquivos aqui',
      },
    },
  },
  /* Import */
  {
    name: 'import',
    component: ImportField,
    wrappers: ['fieldset'],
    defaultOptions: {
      templateOptions: {
        label: 'Adicione os arquivos aqui',
      },
    },
  },
  /** Select Table */
  {
    name: 'select_table',
    wrappers: null,
    component: SelectTableComponent,
  },
  {
    name: 'multi_select',
    wrappers: ['fieldset', 'label'],
    component: MultiSelectComponent,
  }
];
