import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
   <span class="created-by">Created by <b><a href="http://dj.emp.br" target="_blank">
        D&J Tecnologia Social e Educacional
        </a>
      </b>
    </span>
  `,
})
// <div class="socials">
//   <a href="https://www.facebook.com/djtecnologia" target="_blank" class="ion ion-social-facebook"></a>
// </div>
export class FooterComponent {}
