import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

const request = {
  create: 'post',
  update: 'put',
  delete: 'delete',
  sync: 'post',
};

export const Import = importDecorator;

export function importDecorator(target: Function) {
  target.prototype.constructor(HttpClient);

  target.prototype.upload = function(item: any): Observable<any> {
    return this.http.post(
      `${environment.api.url}/${target.prototype.resource}/import`,
      item,
    );
  };

  target.prototype.run = function({ id, method, params }): Observable<any> {
    const route = method === 'sync' ? 'import/sync' : 'import';

    return this.http[request[method]](
      `${environment.api.url}/${target.prototype.resource}/${route}/${id}`,
      params,
    );
  };

  target.prototype.updateLoadedAttributes = function(
    item: any,
  ): Observable<any> {
    return this.http.patch(
      `${environment.api.url}/${target.prototype.resource}/import/${item.id}`,
    );
  };

  target.prototype.preview = function(
    page: number = 1,
    search: string = '',
    otherParams: any,
    filter: any = {},
    includes: string = '',
    orderBy: string = null,
    columns: string = null,
  ): Observable<any> {
    return this.http
      .get(
        `${environment.api.url}/${target.prototype.resource}/import/preview/${
          otherParams.importId
        }`,
      )
      .map(response => {
        response.meta = {
          pagination: {
            currentpage: 1,
            per_page: response.data.length,
            total: response.data.length,
          },
        };

        return response;
      });
  };
}
