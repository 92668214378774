import { Component } from '@angular/core';
import { BaseResourceProvider } from '../../../data/providers/base-resource-provider';
import { FieldType } from '@ngx-formly/core';
import { Providers } from '../../../data/providers/providers';
import { parseFilters, parseText, __nested } from '../../../../helpers';
import { Subscription, empty } from 'rxjs';

@Component({
  selector: 'ngx-multi-select',
  templateUrl: './multi-select.component.html',
})
export class MultiSelectComponent extends FieldType {
  public items: any[] = [];
  public defaultValue: any;
  protected provider: BaseResourceProvider;
  protected endpoint: string;
  protected display_name: string;
  public multiple: boolean = true;

  on = true;
  subscription: Subscription;

  page: number;
  search: string;
  otherParams: any = {};
  requesting = false;
  loading = false;

  ngOnDestroy() {
    this.on = false;
  }

  ngOnInit() {
    this.provider = this.to.resource ? Providers.get(this.to.resource) : null;
    this.endpoint = this.to.endpoint || 'list';
    this.display_name = this.to.display_name || ':name';
    this.multiple = this.to.hasOwnProperty('multiple')
      ? this.to.multiple
      : this.multiple;

    if (this.to.hasOwnProperty('noPaginate')) {
      this.otherParams.noPaginate = 'yes';
    }

    if (this.to.setIfOneOption) {
      this.formState['oneOption_' + this.key] = true;
    }

    if (this.to.refresh) {
      this.to.refresh.forEach(control => {
        let formControl = this.form.get(control);
        if (formControl) {
          formControl.valueChanges
            .takeWhile(() => this.on)
            .debounceTime(500)
            .subscribe(() => {
              this.field.formControl.setValue([]);
              this.getData();
            });
        }
      });
    }

    this.getData();
  }

  enableCustomValue() {
    Object.defineProperty(this.formControl, 'oldSet', {
      value: this.formControl.setValue,
    });

    this.formControl.setValue = (function (items, customValue) {

      return function (value: any, options?: object) {
        let newValue = value;

        if (Array.isArray(value) && value.length) {
          newValue = value.map(item => {
            return customValue === '.'
              ? items[item]
              : __nested(items[item], customValue);
          });
        } else if (!Array.isArray(value) && typeof value === 'number') {
          newValue =
            customValue === '.'
              ? items[value]
              : __nested(items[value], customValue);
        }

        this.oldSet(newValue, options);
      };
    })(this.items, this.to.customValue);
  }

  getData() {
    if (this.to.setValues) {
      this.pushItems(this.to.setValues);
    } else {
      this.requesting = true;
      this.loading = true;

      this.provider[this.endpoint]({
        page: this.page,
        search: this.search,
        otherParams: this.otherParams,
        includes: this.to.includes || '',
        filter: parseFilters(this),
      })
        .debounceTime(500)
        .takeWhile(() => this.requesting)
        .subscribe(response => {
          this.pushItems(response);
          this.loading = false;
          this.requesting = false;
        });
    }
  }

  pushItems(args: { data: any[] } | {}[]) {
    this.items = [];

    (Array.isArray(args) ? args : args.data).forEach((element, index) => {
      this.items.push({
        selectId: this.to.forceindex || this.to.customValue || this.to.setValues ? index : element.id,
        selectText: parseText(<string>this.display_name, element),
        ...element,
      });
    });

    if (this.to.customValue) {
      this.enableCustomValue();
    }

    if (this.formControl.value) {
      this.parseExistingValue();
    }

    this.setIfOneOption();
  }

  setIfOneOption() {
    if (this.to.setIfOneOption && this.items.length == 1) {
      this.formControl.setValue(this.items[0].id);
    } else {
      this.formState['oneOption_' + this.key] = false;
    }
  }

  matchExistingValue(value) {
    let id;

    const existing = __nested(value, this.to.valueId);

    for (let index = 0; index < this.items.length; index++) {
      const element = this.items[index];

      if (__nested(element, this.to.valueId) === existing) {
        id = index;
        break;
      }
    }

    return id;
  }

  parseExistingValue() {
    const ids = [];

    if (Array.isArray(this.formControl.value)) {
      this.formControl.value.forEach((item, index) => {
        if (this.to.setValues) {
          ids.push(this.matchExistingValue(item));
          return;
        }

        ids.push(this.to.forceindex ? index : item.id);

      });
    } else if (this.to.setValues) {
      ids.push(this.matchExistingValue(this.formControl.value));
    }

    this.formControl.setValue(ids.length ? ids : this.formControl.value.id);

    if (this.to.setValues) {
      this.defaultValue = ids;

      this.formControl.valueChanges
        .takeWhile(() => this.defaultValue)
        .subscribe(() => {
          this.defaultValue = null;
        });
    }
  }
}
