import { TableComponent } from "../table/table.component";
import { Component } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { Subscription } from "rxjs";

@Component({
  selector: 'ngx-select-table-field',
  templateUrl: './select-table.component.html'
})
export class SelectTableComponent extends TableComponent {
  pageOnly = false;
  selectControl: AbstractControl;
  subscription: Subscription = new Subscription();

  ngOnInit() {
    super.ngOnInit();

    this.pageOnly = this.to.pageOnly || this.pageOnly;
    this.columns.unshift({
      width: 30,
      sortable: false,
      canAutoResize: false,
      draggable: false,
      resizeable: false,
      headerCheckboxable: true,
      checkboxable: true,
    });

    this.formControl.setValue([]);

    if (this.to.refreshOnSubmit) {
      this.subscription.add(this.options.formState.formSubject
        .subscribe(response => {
          if (response === 'success') {
            this.formControl.setValue([]);
            this.getList();
          }
        })
      );
    }

    if (this.to.selectControl) {
      this.selectControl = this.form.get(this.to.selectControl);

      this.subscription.add(this.selectControl.valueChanges
        .debounceTime(500)
        .subscribe(response => {
          if (response) {
            this.selectAll();
            this.columns[0].headerCheckboxable = false;
          } else {
            if (this.formControl.value.length > 0) this.formControl.setValue([]);
            this.columns[0].headerCheckboxable = true;
          }
        })
      );

      this.subscription.add(
        this.formControl.valueChanges
          .subscribe(response => {
            if (response.length < 1) {
              this.selectControl.setValue(false);
              this.columns[0].headerCheckboxable = true;
            }
          })
      );
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onSelect(event) {
    this.formControl.setValue(event.selected);
  }

  selectAll() {
    this.otherParams.noPaginate = 'yes';
    this.fetch()
      .subscribe(
        response => {
          this.formControl.setValue(response.data);
          delete this.otherParams.noPaginate;
        },
        () => {
          delete this.otherParams.noPaginate;
        });
  }

  rowIdentity(row) {
    return row.id;
  }
}