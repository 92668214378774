import { refreshUser, user } from './../../../helpers';
import { environment } from './../../../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PanelProvider } from './../../../@core/data/providers/panel/panel';
import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { Component, Input, OnInit } from '@angular/core';

import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { UserService } from '../../../@core/data/users.service';
import { AnalyticsService } from '../../../@core/utils/analytics.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  @Input() position = 'normal';

  user: any;
  title: string;

  userMenu = [
    { title: 'Perfil', link: '/p/users/edit/' + user().id, icon: 'nb-person' },
    { title: 'Sair', link: '/auth/logout', icon: 'nb-power' },
  ];

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private userService: UserService,
    private analyticsService: AnalyticsService,
    private authService: NbAuthService,
    public panelProvider: PanelProvider,
    public helper: JwtHelperService,
  ) {
    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      if (token) {
        const decodedToken = this.helper.decodeToken(
          localStorage.getItem(environment.token),
        );
        // here we receive a payload from the token and assigne it to our `user` variable
        if (decodedToken) {
          this.user = decodedToken.user;
          refreshUser();
        }
      }
    });
    this.title = this.panelProvider.config('core.title');
  }

  ngOnInit() { }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  toggleSettings(): boolean {
    this.sidebarService.toggle(false, 'settings-sidebar');
    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }
}
