import { SlugComponent } from '../fields/slug/slug';
import { AsyncValidators } from './../async-validators';
import { AutoCompleteComponent } from './../fields/autocomplete/autocomplete';
import { DatepickerComponent } from './../fields/datepicker/datepicker';
import { InputmaskComponent } from './../fields/inputmask/inputmask';
import { TextComponent } from '../fields/text/text';
import { FirstOrCreateComponent } from '../fields/firstorcreate/firstorcreate';
import { CommentComponent } from '../fields/comment/comment';
import { HiddenMapComponent } from '../fields/hiddenmap/hiddenmap';
import { PasswordComponent } from '../fields/password/password';
import { StatusComponent } from '../fields/status/status.component';
import { LinkComponent } from '../fields/link/link';
import { SearchComponent } from '../fields/search/search.component';
import { FilterGroupComponent } from '../fields/filter-group/filter-group.component';
import { CheckboxComponent } from '../fields/checkbox/checkbox.component';
import { AnalyticsComponent } from '../fields/analytics/analytics.component';

export const BASIC_TYPES = [
  /* Analytics */
  {
    name: 'analytics',
    wrappers: null,
    component: AnalyticsComponent,
  },
  /* Search */
  {
    name: 'search',
    wrappers: null,
    component: SearchComponent,
  },
  /* Password */
  {
    name: 'password',
    wrappers: ['fieldset', 'label'],
    component: PasswordComponent,
  },
  /* HIDDEN MAP */
  {
    name: 'hiddenmap',
    component: HiddenMapComponent,
  },
  /* FIRST OR CREATE */
  {
    name: 'firstorcreate',
    component: FirstOrCreateComponent,
    wrappers: ['card'],
  },
  /* INPUT MASK */
  {
    name: 'inputmask',
    component: InputmaskComponent,
    extends: 'input',
    wrappers: ['fieldset', 'label'],
  },
  /* DATEPICKER */
  {
    name: 'datepicker',
    component: DatepickerComponent,
    wrappers: ['fieldset', 'label'],
    extends: 'input',
  },
  /* AUTOCOMPLETE */
  {
    name: 'autocomplete',
    component: AutoCompleteComponent,
    wrappers: ['fieldset', 'label'],
    defaultOptions: {
      templateOptions: {
        optionValue: 'id',
        optionTitle: 'name',
        optionTitleExpression: null,
        controlDisplay: null,
        controlDisplayExpression: null,
        resource: 'user',
        attribute: 'user',
      },
    },
  },
  /** COMMENT */
  {
    name: 'comment',
    component: CommentComponent,
    wrappers: ['fieldset', 'label'],
    defaultOptions: {
      templateOptions: {
        resource: 'group',
      },
    },
  },
  /* BEGIN */
  {
    name: 'begin',
    extends: 'datepicker',
    defaultOptions: {
      templateOptions: {
        label: 'Início',
        required: true,
      },
    },
  },
  /* END */
  {
    name: 'end',
    extends: 'datepicker',
    defaultOptions: {
      templateOptions: {
        label: 'Término',
      },
    },
  },
  /* TYPE */
  {
    name: 'type',
    extends: 'select',
    defaultOptions: {
      templateOptions: {
        label: 'Tipo',
        required: true,
      },
    },
  },
  /* TEXT */
  {
    name: 'text',
    component: TextComponent,
    wrappers: ['fieldset', 'label'],
  },
  {
    name: 'link',
    component: LinkComponent,
    wrappers: ['fieldset', 'label'],
  },
  {
    name: 'btn_status',
    component: StatusComponent,
    wrappers: ['fieldset', 'label'],
    defaulOptions: {
      templateOptions: {
        options: [],
        value: '',
      },
    },
  },
  /* STATUS */
  {
    name: 'status',
    extends: 'select',
    defaultOptions: {
      templateOptions: {
        label: 'Status',
        required: true,
        options: [
          { label: 'Ativo', value: 'active' },
          { label: 'Inativo', value: 'inactive' },
        ],
      },
    },
  },
  {
    name: 'uf',
    extends: 'select',
    defaultOptions: {
      templateOptions: {
        label: 'Estado',
        options: [
          { label: 'AC', value: 'AC' },
          { label: 'AL', value: 'AL' },
          { label: 'AP', value: 'AP' },
          { label: 'AM', value: 'AM' },
          { label: 'BA', value: 'BA' },
          { label: 'CE', value: 'CE' },
          { label: 'DF', value: 'DF' },
          { label: 'ES', value: 'ES' },
          { label: 'GO', value: 'GO' },
          { label: 'MA', value: 'MA' },
          { label: 'MT', value: 'MT' },
          { label: 'MS', value: 'MS' },
          { label: 'MG', value: 'MG' },
          { label: 'PA', value: 'PA' },
          { label: 'PB', value: 'PB' },
          { label: 'PR', value: 'PR' },
          { label: 'PE', value: 'PE' },
          { label: 'PI', value: 'PI' },
          { label: 'RJ', value: 'RJ' },
          { label: 'RN', value: 'RN' },
          { label: 'RS', value: 'RS' },
          { label: 'RO', value: 'RO' },
          { label: 'RR', value: 'RR' },
          { label: 'SC', value: 'SC' },
          { label: 'SP', value: 'SP' },
          { label: 'SE', value: 'SE' },
          { label: 'TO', value: 'TO' },
        ],
      },
    },
  },
  {
    name: 'slug',
    component: SlugComponent,
    wrappers: ['fieldset', 'label'],
  },
  /* NAME */
  {
    name: 'name',
    extends: 'input',
    defaultOptions: {
      templateOptions: {
        label: 'Nome',
        required: true,
        resource: '',
      },
    },
  },
  {
    name: 'shortname',
    extends: 'slug',
    defaultOptions: {
      templateOptions: {
        label: 'Nome Curto',
        description: 'Digite o nome curto',
        required: true,
      },
    },
  },
  {
    name: 'unique_shortname',
    extends: 'shortname',
    defaultOptions: {
      asyncValidators: {
        unique: AsyncValidators.unique,
      },
    },
  },
  {
    name: 'unique_name',
    extends: 'input',
    defaultOptions: {
      asyncValidators: {
        unique: AsyncValidators.unique,
      },
    },
  },
  /* CPF */
  {
    name: 'cpf',
    extends: 'inputmask',
    defaultOptions: {
      templateOptions: {
        label: 'CPF',
        mask: 'cpf',
        parser: 'cpf',
        maskGuide: true,
        resource: 'user',
        description: '000.000.000-00',
      },
      validators: {
        validation: ['cpf'],
      },
    },
  },
  // CNPJ
  {
    name: 'cnpj',
    extends: 'inputmask',
    defaultOptions: {
      templateOptions: {
        label: 'CNPJ:',
        mask: 'cnpj',
        parser: 'cnpj',
        maskGuide: true,
        resource: 'user',
        description: '00.000.000/0000-00',
      },
      validators: {
        validation: ['cnpj'],
      },
    },
  },
  {
    name: 'unique_cnpj',
    extends: 'cnpj',
    asyncValidators: {
      unique: AsyncValidators.unique,
    },
  },
  {
    name: 'tel',
    extends: 'inputmask',
    defaultOptions: {
      templateOptions: {
        label: 'Telefone',
        mask: 'phone8',
        parser: 'phone',
        maskGuide: true,
        description: '(00) 0000-0000',
      },
    },
  },
  {
    name: 'cel',
    extends: 'inputmask',
    defaultOptions: {
      templateOptions: {
        label: 'Telefone',
        mask: 'phone',
        parser: 'phone',
        maskGuide: true,
        description: '(00) 0000-00000',
      },
    },
  },
  {
    name: 'cep',
    extends: 'inputmask',
    defaultOptions: {
      templateOptions: {
        label: 'CEP',
        mask: 'cep',
        parser: 'cep',
        maskGuide: true,
        description: '00000-000',
      },
    },
  },
  {
    name: 'inputlink',
    extends: 'input',
    defaultOptions: {
      templateOptions: {
        description: 'http://example.com',
      },
      validators: {
        validation: ['url'],
      },
    },
  },
  {
    name: 'filter_group',
    component: FilterGroupComponent,
    wrappers: ['label', 'fieldset'],
  },
  {
    name: 'checkbox',
    component: CheckboxComponent,
    wrappers: ['label', 'fieldset'],
  },
];
