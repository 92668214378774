import { config, user, removeData, pluck, parseText } from './../../../helpers';
import { FormlyFormOptions } from '@ngx-formly/core';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import 'rxjs/add/operator/take';
import { BaseResourceProvider } from '../../../@core/data/providers/base-resource-provider';
import { AuthProvider } from '../../../@core/data/providers/auth/auth';
import { Providers } from '../../../@core/data/providers/providers';

@Component({
  selector: 'scene',
  templateUrl: './scene.component.html',
  styleUrls: ['./scene.component.scss'],
})
export class SceneComponent implements OnInit {
  config: any;
  model: any = {};
  fields: any;
  params: any;
  routeParams: any;
  routeData: any = {};
  provider: BaseResourceProvider;
  ready = false;
  options: FormlyFormOptions = {
    formState: {},
  };

  loadActions: Array<{
    type: string; // link, post, cancel, reset
    method?;
    toast?;
    redirect?;
    redirectTo?;
    params?;
    permission?;
    wrap_params?;
    changes?;
  }> = [];

  constructor(private route: ActivatedRoute, public auth: AuthProvider) {
    this.route.data.subscribe(data => {
      this.routeData = data;
      this.config = config(data.sceneConfig);
    });
    this.route.params.subscribe(params => {
      this.routeParams = params;
    });
  }

  async ngOnInit() {
    this.provider = Providers.get(this.config.provider);
    await this.loadModel();
    this.getModel();
    this.parseOneOrMany();
    this.parseFields();

    if (this.config.onLoad) {
      this.loadActions = this.config.onLoad;
    }
    // Shoud we load permissions here in the future?
    // await this.loadPermission();
    this.ready = true;
  }

  parseFields() {
    if (this.config.defaultClassName) {
      this.options.formState.className = this.config.defaultClassName;
    }
    if (typeof this.config.fields === 'string') {
      this.config.fields = config(this.config.fields);
    }
    this.fields = JSON.parse(JSON.stringify(this.config.fields));
    this.parseFieldGroups(this.fields);
  }

  parseFieldGroups(fields) {
    fields.forEach(element => {
      if (element.fieldGroup && typeof element.fieldGroup === 'string') {
        element.fieldGroup = JSON.parse(
          JSON.stringify(config(element.fieldGroup)),
        );
        this.parseFieldGroups(element.fieldGroup);
      }
    });
  }

  getModel() {
    if (this.config.loadModel && this.config.loadModel.prop) {
      this.model = this.model[this.config.loadModel.prop];
    }

    this.model = removeData(this.model);
  }

  parseOneOrMany() {
    if (this.config.oneOrMany) {
      if (this.model.length === 1) {
        this.model = removeData(this.model[0]);
        this.config.fields = this.config.fieldsOne;
      } else {
        this.config.fields = this.config.fieldsMany;
      }
    }
  }

  parseParams() {
    this.params = {};

    const otherParams = this.config.loadModel.otherParams;
    if (otherParams && otherParams.source === 'user') {
      Object.assign(this.params, pluck(user(), otherParams.props));
    }

    Object.assign(this.params, this.config.loadModel.params);

    if (this.params.includes) {
      this.params.includes = parseText(this.params.includes, this.routeParams, '!:');
    }

    if (this.config.loadModel.routeParams) {
      Object.assign(this.params, pluck(this.routeParams, this.config.loadModel.routeParams));
    } else {
      Object.assign(this.params, this.routeParams);
    }

  }

  async loadModel() {
    if (this.config.loadModel) {
      if (this.config.loadModel.fromRoute) {
        Object.assign(this.model, this.routeParams);
      } else {
        this.config.loadModel =
          this.config.loadModel === true ? {} : this.config.loadModel;

        const providerName =
          this.config.loadModel.provider || this.config.provider;

        const provider = Providers.get(providerName);
        const method = this.config.loadModel.method || 'get';

        this.parseParams();

        this.model = await provider[method](this.params)
          .take(1)
          .toPromise();
      }
    }
  }

  async loadPermission() {
    let permissionComponent =
      this.config.permissionComponent || this.routeData.permissionComponent;
    if (permissionComponent) {
      await this.auth
        .loadPermissionsByComponent(permissionComponent)
        .toPromise();
    }
  }
}
