import { tablePermissions, tableResourceLinks, parseText } from "../../helpers";

export const defaultActionsTypes = {
  edit: (action: any, resource: string, field = null) => {
    action.type = 'link';
    action.link = action.link || tableResourceLinks(resource, 'edit');
    action.icon = action.icon || 'ion-ios-compose-outline';
    action.title = 'core.' + resource + '_form_edit_title';
    action.permission = action.permission || tablePermissions(resource, 'edit');
  },
  show: (action: any, resource: string, field = null) => {
    action.type = 'link';
    action.link = action.link || tableResourceLinks(resource, 'show');
    action.icon = action.icon || 'ion-ios-search';
    action.title = 'core.' + resource + '_form_show_title';
    action.permission =
      'permission' in action
        ? action.permission
        : tablePermissions(resource, 'show');
  },
  delete: (action: any, resource: string, field = null) => {
    action.type = 'swal';
    action.title = 'core.' + resource + '_form_delete_title';
    action.swal = {
      title: 'core.' + resource + '_form_delete_message',
      type: 'question',
      showCancelButton: true,
      focusCancel: true,
    };
    action.icon = action.icon || 'ion-ios-trash-outline';
    action.confirm = field.run;
    action.method = 'destroy';
    action.params = { model: ['id'] };
    action.permission =
      action.permission || tablePermissions(resource, 'delete');
  },
  link: (action: any, resource: string, field = null) => {
    action.link = parseText(action.link, field.model);
  },
  swal: (action: any, resource: string, field = null) => {
    action.type = 'swal';
    action.confirm = field.run;
    action.swal = action.swal || {};
    action.swal = {
      title: action.swal.title || 'Tem certeza que deseja realizar esta ação ?',
      type: action.swal.type || 'question',
      showCancelButton: action.swal.hasOwnProperty('showCancelButton') ? action.swal.showCancelButton : true,
      focusCancel: action.swal.hasOwnProperty('focusCancel') ? action.swal.focusCancel : true,
    };
  }
};
