import { Input, ViewChild, OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  FormlyFieldConfig,
  FormlyFormOptions,
  FormlyForm,
} from '@ngx-formly/core';
import { BaseResourceProvider } from '../../../../@core/data/providers/base-resource-provider';
import { ToasterService, ToasterConfig, Toast } from 'angular2-toaster';
import 'style-loader!ngx-bootstrap/datepicker/bs-datepicker.css';
import 'style-loader!angular2-toaster/toaster.css';
import { Location } from '@angular/common';
import { _ } from 'underscore';
import { lang, langc, removeData } from './../../../../helpers';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit, OnDestroy {
  @ViewChild(FormlyForm) formly: FormlyForm;
  form: FormGroup;
  loading = false;
  options: FormlyFormOptions = {
    formState: {},
  };
  fieldsParsed: Array<FormlyFieldConfig> = [];
  model: any;

  @Input('fields') fieldsInput: any;
  @Input() provider: BaseResourceProvider;
  @Input('form') formConfig: any;
  @Input() showToast = true;
  @Input() showCancel = true;
  @Input() showSubmit = true;
  @Input() redirect = true;
  @Input() redirectTo = 'back';
  @Input('model') targetModel: any;

  @Input()
  toasterConfig = new ToasterConfig({
    positionClass: 'toast-top-right',
    timeout: 15000,
    animation: 'slideDown',
    limit: 1,
  });

  @Input()
  toastSuccess: Toast = {
    type: 'success',
    title: 'Registro salvo com sucesso!',
    showCloseButton: true,
  };

  @Input()
  toastError: Toast = {
    type: 'error',
    title: 'Erro ao salvar registro!',
    showCloseButton: true,
  };

  constructor(
    private toasterService: ToasterService,
    private location: Location,
    private router: Router,
  ) {}

  ngOnInit() {
    this.form = new FormGroup({});
    this.defaults();
    this.parseModel();
    this.parseFields();
    this.options.formState.resource_id = this.model ? this.model.id : null;
  }

  defaults() {
    this.showToast =
      this.formConfig.showToast === undefined
        ? this.showToast
        : this.formConfig.showToast;
    this.showCancel =
      this.formConfig.showCancel === undefined
        ? this.showCancel
        : this.formConfig.showCancel;
    this.showSubmit =
      this.formConfig.showSubmit === undefined
        ? this.showSubmit
        : this.formConfig.showSubmit;
    this.redirect =
      this.formConfig.redirect === undefined
        ? this.redirect
        : this.formConfig.redirect;
    this.redirectTo =
      this.formConfig.redirectTo === undefined
        ? this.redirectTo
        : this.formConfig.redirectTo;
  }
  ngOnDestroy() {
    // this.fieldsParsed = null;
    // this.form = null;
    // this.model = null;
  }

  parseModel() {
    const model = JSON.parse(JSON.stringify(removeData(this.targetModel)));
    this.model = {};
    this.model = Object.assign({}, model);
    setTimeout(() => {
      this.form.reset(this.model);
    });
  }

  parseFields() {
    let fields = JSON.parse(JSON.stringify(this.fieldsInput)) || [];
    if (fields) {
      fields = _.values(fields);
    }
    fields.forEach(item => {
      item.templateOptions = item.templateOptions || {};
      if (!item.className) {
        item.className = this.formConfig.className || 'col-12';
      }
      if (item.lang) {
        item.templateOptions.label = lang(item.lang);
      }
      if (item.langc) {
        item.templateOptions.label = langc(item.langc[0], item.langc[1]);
      }
      item.expressionProperties = item.expressionProperties || {};
      item.expressionProperties['templateOptions.model'] = 'model';
    });
    this.fieldsParsed = [
      {
        fieldGroupClassName: 'row',
        fieldGroup: fields,
      },
    ];
  }

  doRedirect() {
    if (this.redirect) {
      switch (this.redirectTo) {
        case 'back':
          this.location.back();
          break;
        default:
          this.router.navigate([this.redirectTo]);
          break;
      }
    }
  }

  doToast(toast: Toast) {
    if (this.showToast) {
      this.toasterService.popAsync(toast);
    }
  }
  cancel() {
    this.location.back();
  }

  submit() {
    this.loading = true;
    if (this.model.id) {
      this.provider
        .put(this.model)
        .toPromise()
        .then(() => {
          this.loading = false;
          this.doToast(this.toastSuccess);
          this.doRedirect();
        })
        .catch(() => {
          this.loading = false;
          this.doToast(this.toastError);
        });
    } else {
      this.provider
        .add(this.model)
        .toPromise()
        .then(() => {
          this.loading = false;
          this.doToast(this.toastSuccess);
          this.doRedirect();
        })
        .catch(() => {
          this.loading = false;
          this.doToast(this.toastError);
        });
    }
  }
}
