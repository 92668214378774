import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'ngx-link',
  templateUrl: 'link.html',
})
export class LinkComponent extends FieldType {
  link = '';
  link_label = '';

  ngOnInit() {
    this.link = this.field.formControl.value;
    this.link_label = this.field.templateOptions.linkLabel || this.link.replace(/^(s?f|ht)tps?\W+/, '')
      .replace(/\/$/, '');
  }

}
