import { Component, Input } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { Providers } from '../../../data/providers/providers';
import { ToasterService, ToasterConfig, Toast } from 'angular2-toaster';
import { Location } from '@angular/common';

@Component({
  selector: 'ngx-import-field',
  templateUrl: './import-field.html',
})
export class ImportField extends FieldType {
  fileModel: any;
  fileFields: any = [];
  fileForm: FormGroup;

  tableModel: any = {};
  tableFields: any = [];
  tableForm: FormGroup;

  endFields: any = [];
  endForm: FormGroup;

  importData: any;
  provider: any;

  view_mode = {
    uploading: true,
    finished: false,
  };
  row_warnings = [];
  warnings = null;
  show_warning_details = false;

  toasterConfig = new ToasterConfig({
    positionClass: 'toast-top-right',
    timeout: 15000,
    animation: 'slideDown',
    limit: 1,
  });

  loading = false;

  @Input() showToast = true;

  constructor(private toasterService: ToasterService, private location: Location) {
    super();
  }

  ngOnInit() {
    this.provider = Providers.get(this.field.templateOptions.resource);
    this.fileForm = new FormGroup({});
    this.tableForm = new FormGroup({});
    this.endForm = new FormGroup({});
    this.fileModel = { attachment_file: null };
    this.fileFields = [
      {
        key: 'attachment_file',
        type: 'filepond',
        templateOptions: {
          resource: 'syncs',
          label: 'Arquivo de Importação',
          required: true,
        },
      },
    ];

    if (this.field.templateOptions.upload_fields) {
      this.fileFields = this.fileFields.concat(
        this.field.templateOptions.upload_fields,
      );
    }

    if (this.field.templateOptions.table_fields) {
      this.tableFields = this.field.templateOptions.table_fields;
    }
  }

  doToast(toast: Toast) {
    if (this.showToast) {
      this.toasterService.popAsync(toast);
    }
  }

  upload() {
    this.loading = true
    this.provider.upload(this.fileModel).subscribe(
      response => {
        this.importData = response;
        this.view_mode.uploading = false;
        this.fillActionTable();
        this.onTableFormChange();
        this.loading = false;
      },
      () => {
        this.loading = false;
        this.doToast({
          type: 'error',
          title: 'Erro ao carregar o arquivo',
          showCloseButton: true,
        });
      },
    );
  }

  run() {
    this.loading = true;
    const id = this.importData.importId;
    this.provider.run({ id, ...this.tableModel }).subscribe(
      response => {
        this.importData = response;
        this.view_mode.finished = true;
        this.fillEndTable();
        this.loading = false;
      },
      () => {
        this.loading = false;
        this.doToast({
          type: 'error',
          title: 'O servidor não pôde executar esta ação',
          showCloseButton: true,
        });
      },
    );
  }

  onTableFormChange() {
    this.tableForm.valueChanges.subscribe(() => {
      const fc = this.tableForm.get('method');

      if (fc && fc.value) {
        this.setWarnings();
        this.setRowWarnings();
      }
    });
  }

  setRowWarnings() {
    let warning = this.importData.row_warnings;

    if (warning) {
      warning = warning[this.tableModel.method];

      this.row_warnings = Object.keys(warning).map(row => {
        return {
          key: parseInt(row) + 2,
          value: Object.values(warning[row]).join(','),
        };
      });
    }
  }

  setWarnings() {
    let warning = this.importData.warnings;

    if (warning) {
      warning = warning[this.tableModel.method];

      this.warnings = warning ? Object.values(warning).join(',') : null;
    }
  }

  fillActionTable() {
    this.tableFields.unshift({
      key: 'table_data',
      type: 'table',
      templateOptions: {
        columns: this.importData.columns.map(function (item) {
          return { name: item, prop: item };
        }),
        resource: this.provider.getResource(),
        provider: this.provider,
        json_rows: {
          data: this.importData.data,
          meta: {
            pagination: {
              currentpage: 1,
              per_page: this.importData.data.length,
              total: this.importData.data.length,
            },
          },
        },
        externalPaging: false,
      },
    });
  }

  fillEndTable() {
    const columns = this.importData.columns;
    columns.push('error');

    this.endFields = [
      {
        key: 'import_result',
        type: 'table',
        templateOptions: {
          columns: columns.map(item => {
            return { name: item, prop: item };
          }),
          resource: this.provider.getResource,
          provider: this.provider,
          json_rows: {
            data: this.importData.data,
            meta: {
              pagination: {
                currentpage: 1,
                per_page: this.importData.data.length,
                total: this.importData.data.length,
              },
            },
          },
          externalPaging: false,
          scrollbarH: true,
        },
      },
    ];
  }
}
