import { Providers } from './../../../data/providers/providers';
import { Component } from '@angular/core';
import { FieldType, FormlyFormOptions } from '@ngx-formly/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/takeWhile';
import { config, langc } from '../../../../helpers';

/**
 * Generated class for the CepComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'ngx-firstorcreate',
  templateUrl: 'firstorcreate.html',
})
export class FirstOrCreateComponent extends FieldType {
  on = true;
  loading = false;
  public modeRadio: FormGroup;

  firstModel: any;
  firstFields: any;
  firstForm: FormGroup;
  firstOptions: FormlyFormOptions = {
    formState: {
      enabled: false,
    },
  };

  createModel: any;
  createFields: any;
  createForm: FormGroup;
  createOptions: FormlyFormOptions = {
    formState: {
      enabled: false,
    },
  };

  fisrtorcreate_first_lang: string;
  fisrtorcreate_create_lang: string;
  fisrtorcreate_helper_lang: string;
  fisrtorcreate_model_lang: string;

  mode: string;
  modelLoaded: any;
  state: string;

  constructor(private formBuilder: FormBuilder) {
    super();

    this.modeRadio = this.formBuilder.group({
      mode: null,
    });

    this.fisrtorcreate_first_lang = 'Já tenho cadastro';
    this.fisrtorcreate_create_lang = 'Não tenho cadastro';
    this.fisrtorcreate_helper_lang =
      'Se você já tem cadastro se identifique, caso contrário faça seu cadastro.';

    this.modeRadio
      .get('mode')
      .valueChanges.debounceTime(500)
      .takeWhile(() => {
        return this.on;
      })
      .subscribe(() => {
        const mode = this.modeRadio.get('mode').value;
        this.mode = mode;
        this.changeState(mode);
      });

    this.firstModel = {};
    this.firstForm = new FormGroup({});

    this.firstForm.statusChanges
      .debounceTime(500)
      .takeWhile(() => {
        return this.on;
      })
      .subscribe(() => {
        if (this.mode === 'first') {
          if (this.firstForm.valid) {
            this.getFirst();
          } else {
            this.formControl.setValue(null);
          }
        }
      });

    this.createModel = {};
    this.createForm = new FormGroup({});
  }

  ngOnInit() {
    this.firstFields = [config(this.to.firstForm)];

    // Bind enable state
    this.firstFields.forEach(item => {
      item.hideExpression = '!formState.enabled';
    });

    this.createFields = config(this.to.createForm);
    // Bind enable state
    this.createFields.forEach(item => {
      item.hideExpression = '!formState.enabled';
    });
  }

  ngOnDestroy() {
    this.on = false;
  }

  changeState(state: string) {
    // Remover Loading
    // Remover erros
    this.loading = false;
    this.formControl.setErrors({});
    this.state = state;
    switch (state) {
      case 'first':
        // Habilitar form first;
        // Desabilitar form create;
        this.createOptions.formState.enabled = false;
        this.firstOptions.formState.enabled = true;
        break;
      case 'create':
        // Habilitar form create;
        // Desabilitar form first;
        this.createOptions.formState.enabled = true;
        this.firstOptions.formState.enabled = false;
        break;
      case 'creating':
        // Habilitar Loading
        this.loading = true;
        break;
      case 'searchingfirst':
        // Habilitar Loading
        this.loading = true;
        break;
      case 'firstfail':
        // Setar Erro de firstfail
        // Atualizar Validação
        // Marcar o form como atualizado
        this.formControl.setErrors({ 'firstfail': true });
        this.formControl.updateValueAndValidity();
        this.formControl.markAsTouched();
        break;
      case 'createfail':
        // Setar Erro de firstfail
        // Atualizar Validação
        // Marcar o form como atualizado
        this.formControl.setErrors({ 'createfail': true });
        this.formControl.updateValueAndValidity();
        this.formControl.markAsTouched();

        break;
      case 'firstsuccess':
        // Marcar o form como atualizado
        // Atualizar Validação
        this.formControl.updateValueAndValidity()
        this.formControl.markAsTouched();
        this.fisrtorcreate_model_lang = langc(this.to.model_lang, 1, this.modelLoaded);
        break;
      case 'createsuccess':
        // Marcar o form como atualizado
        // Atualizar Validação
        // Desabilitar mode
        // Ocultar Form
        this.formControl.updateValueAndValidity()
        this.formControl.markAsTouched();
        this.modeRadio.get('mode').disable();
        this.fisrtorcreate_model_lang = langc(this.to.model_lang, 1, this.modelLoaded);
        break;
      default:
        break;
    }
  }


  getFirst() {
    this.changeState('searchingfirst');
    const resource = this.to.resource;
    const provider = Providers.get(resource);
    const field = this.firstFields[0];
    const params = {
      searchFields: field.key + ':=',
      searchJoin: 'and',
    };
    const value = this.firstModel[field.key];
    const search = field.key + ':' + value;
    this.formControl.setValue(null);
    provider
      .list({ page: 1, search: search, otherParams: params })
      .map(response => {
        if (response.data && response.data[0]) {
          this.modelLoaded = response.data[0];
          return response.data[0].id;
        }
        return null;
      })
      .subscribe(id => {
        if (id) {
          this.changeState('firstsuccess');
          this.formControl.setValue(id);
        } else {
          this.changeState('firstfail');
        }
      });
  }

  create() {
    const resource = this.to.resource;
    const provider = Providers.get(resource);
    this.changeState('creating');
    provider
      .add(this.createModel)
      .subscribe(response => {
        if (response) {
          this.modelLoaded = response;
          this.formControl.setValue(response.id);
          this.changeState('createsuccess');
        }
      }, () => {
        this.changeState('createfail');
      });
  }
}
