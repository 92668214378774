import { FieldType } from '@ngx-formly/core';
import { Component } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ngx-btn-checkbox',
  templateUrl: './filter-group.component.html',
})
export class FilterGroupComponent extends FieldType {
  formGroup: FormGroup;
  btnOptions = [];
  subscription: Subscription;

  ngOnInit() {
    this.btnOptions = (<any[]>this.to.options);
    const controls = {};
    const fields = {}

    this.btnOptions.forEach(element => {
      controls[element.value] = new FormControl();
      fields[element.value] = element.field;
    });

    this.formGroup = new FormGroup(controls);

    this.subscription = this.formGroup.valueChanges.subscribe((control) => {
      const selected = {};

      for (const item in control) {
        if (control[item]) {
          selected[fields[item]] = !selected[fields[item]] ? item : selected[fields[item]] + ',' + item;
        }
      }

      this.formControl.setValue(selected);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
