import { Injectable } from '@angular/core';
import { BaseResourceProvider } from '../base-resource-provider';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

/*
  Generated class for the EnrolmentProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class EnrolmentProvider extends BaseResourceProvider {
  constructor(public http: HttpClient) {
    super(http, 'enrolments');
  }

  addUser({ enrolment, user, user_enrolment, otherParams = {} }): Observable<any> {
    const params = {
      enrolment_id: enrolment,
      user_enrolment: user_enrolment,
      ...otherParams,
    };

    params[user.id ? 'user_id' : 'user'] = user.id || user;

    return this.http.post(`${environment.api.url}/${this.resource}/adduser`, params);
  }

  sync({ enrolments, user_enrolment, users, shouldDelete }): Observable<any> {
    const parsed = this.__parse(enrolments, user_enrolment, users);

    return this.http.post(`${environment.api.url}/${this.resource}/sync`, {
      enrolments: parsed.enrol_ids,
      user_enrolments: parsed.user_enrolments,
      shouldDelete: shouldDelete
    });
  }

  detach({ enrolments, user_enrolment, users }) {
    const parsed = this.__parse(enrolments, user_enrolment, users);

    return this.http.post(`${environment.api.url}/${this.resource}/detach`, {
      enrolments: parsed.enrol_ids,
      user_enrolments: parsed.user_enrolments,
    });
  }

  protected __parse(enrolments = null, user_enrolment, users) {
    let enrol_ids: any[] = [];

    const user_enrolments = users.map((item) => {
      const enrolment_id = item.hasOwnProperty('userEnrolments') ?
        item.userEnrolments.enrolment_id : enrolments.id;

      if (!enrol_ids.includes(enrolment_id)) {
        enrol_ids.push(enrolment_id);
      }

      return { user_id: item.id, enrolment_id: enrolment_id, ...user_enrolment };
    });

    return { enrol_ids: enrol_ids, user_enrolments: user_enrolments };
  }
}
