export const COURSE_TYPES = [
  /* COURSE */
  {
    name: 'course',
    extends: 'autocomplete',
    defaultOptions: {
      templateOptions: {
        label: 'Curso',
        attribute: 'course',
        resource: 'course',
        controlDisplay: 'name',
      },
    },
  },
];
