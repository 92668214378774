import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'ngx-text',
  templateUrl: 'text.html',
})
export class TextComponent extends FieldType {
  values: any[] = [];
  cardClass: string = 'card-body';

  ngOnInit() {
    if (!this.to.card) {
      if (Array.isArray(this.formControl.value)) {
        this.formControl.value.forEach(element => {
          this.values.push(this.parseObjectValue(element));
        });
      } else {
        this.values.push(this.parseObjectValue(this.formControl.value));
      }
    }
  }

  parseObjectValue(value) {
    //console.log(value);

    if (typeof value === 'object' && this.to.attribute) {
      return value[this.to.attribute];
    }
    return value;
  }
}
