import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';


@Component({
  selector: 'ngx-wrapper-break_line',
  template: `
    <ng-container #fieldComponent></ng-container><br *ngIf="should_break">
  `,
})
export class BreakLineWrapperComponent extends FieldWrapper {
  @ViewChild('fieldComponent', { read: ViewContainerRef })
  fieldComponent: ViewContainerRef;

  get should_break() {
    console.log(this.to.break_line);

    return this.to.break_line;
  }
}
