export const CLASSROOM_TYPES = [
  /* CLASSROOM */
  {
    name: 'classroom',
    extends: 'autocomplete',
    defaultOptions: {
      templateOptions: {
        label: 'core.classrooms',
        attribute: 'classroom',
        resource: 'classroom',
        required: true,
        controlDisplay: 'name',
      },
    },
  },
];
