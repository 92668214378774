import { FormGroup, FormArray } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
export function ExpressionPropertyTransformer(
  fields: FormlyFieldConfig[],
  model: any,
  form: FormGroup | FormArray,
  options: FormlyFormOptions,
): FormlyFieldConfig[] {
  fields.map(element => {
    if (element.expressionProperties) {
      let expressions = {};
      for (let exp of element.expressionProperties) {
        expressions[exp.key] = exp.value;
      }
      element.expressionProperties = expressions;
    }

    if (element.fieldGroup) {
      element.fieldGroup = ExpressionPropertyTransformer(
        element.fieldGroup,
        model,
        form,
        options,
      );
    }
  });

  return fields;
}


