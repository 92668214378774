import { CardWrapperComponent } from './wrappers/card/card.component';
import { PermissionWrapperComponent } from './wrappers/permission/permission.component';
import { ResetWrapperComponent } from './wrappers/reset/reset.component';
import { ClearSelf } from './wrappers/clear-self/clear-self.component';
import { FirstOrCreate } from './wrappers/first-or-create/first-or-create.component';
import { CustomLabelComponent } from './wrappers/custom_label/custom_label.component';
import { BreakLineWrapperComponent } from './wrappers/break-line/break-line.component';

export const FormlyWrappers = [
  { name: 'card', component: CardWrapperComponent },
  { name: 'permission', component: PermissionWrapperComponent },
  { name: 'reset', component: ResetWrapperComponent },
  { name: 'clear_self', component: ClearSelf },
  { name: 'firstOrCreate', component: FirstOrCreate },
  { name: 'custom_label', component: CustomLabelComponent },
  { name: 'break_line', component: BreakLineWrapperComponent },
];
