import { Injectable } from '@angular/core';
import { BaseResourceProvider } from '../base-resource-provider';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { Import } from '../../decorators/import.decorator';

/*
  Generated class for the ClassroomProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
@Import
export class ClassroomProvider extends BaseResourceProvider {
  constructor(http: HttpClient) {
    super(http, 'classrooms');
    this.includeList = 'course';
    this.includeGet = '';
  }

  classroomUsers(
    {
      id,
      page = 1,
      search = '',
      otherParams = {},
      filter = {},
      includes = '',
      orderBy = null,
      columns = null,
    }
  ): Observable<any> {
    let params = arguments[0];

    delete params.id;

    const requestOptions = this.parseParams(params);

    return this.http.get(`${environment.api.url}/${this.resource}/${id}/users`, requestOptions);
  }
}
