import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { McBreadcrumbsResolver } from 'ngx-breadcrumbs';
import { Providers } from '../data/providers/providers';
import { lang } from '../../helpers';


@Injectable()
export class ResourceBreadcrumbsResolver extends McBreadcrumbsResolver {

  // Optional: inject any required dependencies
  constructor() {
    super();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const id = route.params.id;

    if (route.data.breadcrumbProvider && id) {
      const breadcrumb = Providers.get(route.data.breadcrumbProvider).get(id).map(model => {
        return [{
          text: lang(route.data.breadcrumbLang, model),
          path: super.getFullPath(route),
        }];
      });
      return breadcrumb;
    }

    return [{
      text: '#NO PROVIDER#',
      path: super.getFullPath(route.parent),
    }];
  }
}
