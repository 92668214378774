import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'ngx-wrapper-card',
  templateUrl: 'card.component.html',
})
export class CardWrapperComponent extends FieldWrapper {
  get footer() {
    return this.to.formActions || false;
  }

  get header() {
    return this.to.header === false ? false : true;
  }

  @ViewChild('fieldComponent', { read: ViewContainerRef })
  fieldComponent: ViewContainerRef;
}
