import { appContainer } from './../../../app.container';
import { PanelProvider } from './panel/panel';
import { ClassroomProvider } from './classroom/classroom';
import { UserProvider } from './user/user';
import { EnrolmentProvider } from './enrolment/enrolment';
import { CourseProvider } from './course/course';
import { GroupProvider } from './group/group';
import { ClassroomRequestProvider } from './classroom_request/classroom_request';

export const Providers = {
  providers: {
    user: UserProvider,
    classroom: ClassroomProvider,
    classroom_request: ClassroomRequestProvider,
    enrolment: EnrolmentProvider,
    course: CourseProvider,
    group: GroupProvider,
    panel: PanelProvider,
    // 'user-enrolment': UserEnrolmentProvider,
  },

  get(name: string): any {
    return appContainer().get(this.providers[name]);
  },
};
