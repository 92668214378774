import { Injector } from '@angular/core';

/**
 * Created by dayvisson on 28/06/17.
 *
 * Serviço para criar outros serviços quando não é possivel utilizar
 * a injeção de dependências do Ionic
 */
let localInjector: Injector;
export const appContainer = (injector?: Injector): Injector => {
  if (injector) {
    localInjector = injector;
  }
  return localInjector;
};
