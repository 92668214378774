import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Parsers } from '../../../utils/parsers';

@Component({
  selector: 'ngx-password',
  templateUrl: 'password.html',
})
export class PasswordComponent extends FieldType {
  type = 'password';


  constructor() {
    super();
  }

  toogle() {
    this.type = this.type === 'password' ? 'text' : 'password';
  }
}
