import { Injectable } from '@angular/core';
import { BaseResourceProvider } from '../base-resource-provider';
import { HttpClient } from '@angular/common/http';
import { Import } from '../../decorators/import.decorator';

/*
  Generated class for the ClassroomProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
@Import
export class CourseProvider extends BaseResourceProvider {
  constructor(public http: HttpClient) {
    super(http, 'courses');
  }
}
