export const GROUP_TYPES = [
  /* Group */
  {
    name: 'group',
    extends: 'autocomplete',
    defaultOptions: {
      templateOptions: {
        label: 'Grupo',
        resource: 'group',
        optionValue: 'id',
        controlDisplay: 'name',
      },
    },
  },
];