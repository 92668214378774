import { Observable } from 'rxjs/Observable';
import { Injectable, Injector } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponseBase,
  HttpResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { NbTokenStorage } from '@nebular/auth';
import { ToasterService, Toast } from 'angular2-toaster';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private _injector: Injector) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const token = event.headers.get('authorization');
          if (token) {
            const tokenService = this._injector.get(NbTokenStorage);
            tokenService.setRaw(token.replace('Bearer ', ''));
          }
        } else {
        }
        return event;
      })
      .catch((err: any, caught) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.doRedirectToLogin();
          } else if (err.status === 403) {
            this.doToastNoPermission();
          }
        }
        return Observable.throw(err);
      });
  }

  doRedirectToLogin() {
    const router = this._injector.get(Router);
    router.navigate(['/auth/login']);
  }

  doToastNoPermission() {
    const toaster = this._injector.get(ToasterService);
    const toastError: Toast = {
      type: 'error',
      title: '[ERROR: NO PERMISSIONS]',
      showCloseButton: true,
    };
    toaster.popAsync(toastError);
    console.info('NO PERMISSION');
  }
}
