/**
 * Created by dayvisson on 30/06/17.
 */
export const d = /\d/;
export const d19 = /[1-9]/;
export const Masks = {
  phone: ['(', d19, d, ')', ' ', d, d, d, d, d, '-', d, d, d, d],
  phone8: ['(', d19, d, ')', ' ', d, d, d, d, '-', d, d, d, d],
  rg: [d, d, d, d, d, d, d, d, '-', d],
  cpf: [d, d, d, '.', d, d, d, '.', d, d, d, '-', d, d],
  cnpj: [d, d, '.', d, d, d, '.', d, d, d, '/', d, d, d, d, '-', d, d],
  cep: [d, d, d, d, d, '-', d, d, d],
  date: [d, d, '/', d, d, '/', d, d, d, d],
};

