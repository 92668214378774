import { Injectable } from '@angular/core';
import { BaseResourceProvider } from '../base-resource-provider';
import { HttpClient } from '@angular/common/http';

/*
  Generated class for the UserEnrolmentProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class UserEnrolmentProvider extends BaseResourceProvider {
  constructor(public http: HttpClient) {
    super(http, 'user-enrolments');
  }
}
