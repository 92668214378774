import { environment } from './../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import * as Lang from 'lang.js';

/*
  Generated class for the PanelProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class PanelProvider {
  public _config: any;
  private _lang: Lang;

  public static routes: any = {
    e: {
      core: []
    },
    p: {
      core: []
    },
    b: {
      core: []
    }
  };

  constructor(public http: HttpClient) { }

  config(key: string): any {
    return eval('this._config.' + key);
  }

  lang(key: string | any[], params = {}) {
    if (typeof key === 'string') {
      return this._lang.get(key, params);
    }

    return this.lang_choice(key[0], key[1], params);
  }

  lang_choice(key: string, count: number, params = {}) {
    return this._lang.choice(key, count, params);
  }

  loadConfig(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(environment.api.url + '/panel/config')
        .catch((error: any): any => {
          reject(false);
          return Observable.throw(error.error || 'Server error');
        })
        .subscribe(panel => {
          this._config = panel;
          this._lang = new Lang(this._config.lang);

          // if not authenticated, login as guest
          resolve(true);
        });
    });
  }


}
