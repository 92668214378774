import { langc, parseText } from './../../../helpers';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'card-actions',
  templateUrl: './card-actions.component.html',
  styleUrls: ['./card-actions.component.scss'],
})
export class CardActionsComponent implements OnInit {
  @Input()
  actions: Array<{
    title;
    icon?;
    permission?;
    link?;
  }>;

  @Input() model: any;

  constructor() { }

  ngOnInit() { }

  parseTitle(action, model) {
    return langc(action.title, 1, model);
  }

  parseLink(action) {
    return parseText(action.link, this.model);
  }
}
