import { Exists } from './../../decorators/exists.decorator';
import { Injectable } from '@angular/core';
import { BaseResourceProvider } from '../base-resource-provider';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

/*
  Generated class for the UserProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/

@Injectable()
export class UserProvider extends BaseResourceProvider {
  constructor(public http: HttpClient) {
    super(http, 'users');
    this.orderBy = 'id';
  }

  confirm(id): Observable<any> {
    return this.http.get(`${environment.api.url}/${this.resource}/confirm/${id}`);
  }

  changePassword(params: { id?, token?, password: string | number }) {
    return this.http.put(`${environment.api.url}/change_password`, params);
  }

  forgot(email: string) {
    return this.http.put(`${environment.api.url}/forgot`, { email: email });
  }

  updateByToken(user: object) {
    return this.http.put(`${environment.api.url}/update_by_token`, user);
  }
}
