import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormControl } from '@angular/forms';
import { Providers } from '../data/providers/providers';

/**
 * Created by dayvisson on 30/06/17.
 */
export const AsyncValidators = {
  unique: {
    expression: uniqueExpression,
    message: 'Valor já cadastrado!',
  },
  exists: {
    expression: existsExpression,
    message: 'Valor não encontrado!',
  }
};

export function uniqueExpression(
  control: FormControl,
  field: FormlyFieldConfig,
) {
  let value = control.value;
  // GET a provider by string name
  return new Promise((resolve, reject) => {
    if (control.pristine) {
      resolve(true);
    }

    if (field.parsers) {
      field.parsers.forEach((item, index) => {
        if (item) {
          value = item(value);
        }
      });
    }
    const key = field.templateOptions.uniqueKey || field.key;
    // If model has an Id, adds it as a params to verify unity discard the owner
    if (value) {
      if (field.model && field.model.id) {
        Providers.get(field.templateOptions.resource)
          .exists({ field: key, value: value, id: field.model.id })
          .subscribe(response => {
            resolve(!response.exists || response.exists === field.model.id)
          });
      } else {
        Providers.get(field.templateOptions.resource)
          .exists({ field: key, value: value, })
          .subscribe(response => {
            resolve(!response.exists);
          });
      }
    }
  });
}

export function existsExpression(
  control: FormControl,
  field: FormlyFieldConfig,
) {
  let value = control.value;
  // GET a provider by string name
  return new Promise((resolve, reject) => {
    if (control.pristine) {
      resolve(true);
    }

    if (field.parsers) {
      field.parsers.forEach((item, index) => {
        if (item) {
          value = item(value);
        }
      });
    }
    const key = field.templateOptions.uniqueKey || field.key;

    Providers.get(field.templateOptions.resource)
      .exists({ field: key, value: value, })
      .subscribe(response => {
        resolve(response.exists);
      });

  });
}
