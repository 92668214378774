import { Observable } from 'rxjs/Observable';
import { HttpParams, HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
/**
 * Created by dayvisson on 26/06/17.
 */
export abstract class BaseResourceProvider {
  protected includeList: string;
  protected includeGet: string;
  protected orderBy: string = '';

  constructor(protected http: HttpClient, protected resource: string) { }

  getResource(): string {
    return this.resource;
  }

  protected parseParams({ page = 1,
    search = '',
    otherParams = {},
    filter = {},
    includes = '',
    orderBy = null,
    columns = null, }) {
    let params = new HttpParams();
    params = params.append('page', page + '');

    params = params.append('search', search);
    if (includes) {
      params = params.append('include', includes);
    }
    params = params.append('orderBy', orderBy || this.orderBy);

    Object.keys(otherParams).forEach(key => {
      params = params.append(key, otherParams[key]);
    });

    let filterStr = '';
    Object.keys(filter).forEach(key => {
      const value = filter[key];

      if (value || Array.isArray(value) && value.length > 0) {
        const text = key + ':' + value;
        filterStr += filterStr === '' ? text : ';' + text;
      }
    });

    if (filterStr) {
      params = params.append('filter', filterStr);
    }

    return { params: params };
  }

  list(config:
    {
      page?,
      search?,
      otherParams?,
      filter?,
      includes?,
      orderBy?,
      columns?,
    } = {}
  ): Observable<any> {
    const requestOptions = this.parseParams(config);

    return this.http.get(
      `${environment.api.url}/${this.resource}`,
      requestOptions,
    );
  }

  get(config: { id, includes: '' } | number | string, includes: string = ''): Observable<any> {
    let id;

    if (typeof config === 'object') {
      id = config.id;
      includes = config.includes;
    } else {
      id = config;
    }

    let params = new HttpParams();
    includes = includes || this.includeGet;

    if (includes) {
      params = params.append('include', includes);
    }

    const requestOptions = { params: params };

    return this.http.get(
      `${environment.api.url}/${this.resource}/${id}`,
      requestOptions,
    );
  }

  exists(config: { field, value, id?}): Observable<any> {
    let url = `${environment.api.url}/${this.resource}/exists/${config.field}/${config.value}`;

    if (config.id) {
      url += '/' + config.id;
    }
    return this.http.get(url);
  }

  findBy({ field, value, checkin = 'false', includes = '', filter = {} }): Observable<any> {
    let params = this.parseParams({ includes: includes, filter: filter });

    return this.http.get(`${environment.api.url}/${this.resource}/findBy/${field}/${value}/${checkin}`, params);
  }

  put(item: any): Observable<any> {
    return this.http.put(
      `${environment.api.url}/${this.resource}/${item.id}`,
      item,
    );
  }

  add(item: any): Observable<any> {
    return this.http.post(`${environment.api.url}/${this.resource}`, item);
  }

  destroy(id: number): Observable<any> {
    return this.http.delete(`${environment.api.url}/${this.resource}/${id}`);
  }

  select(search: string = ''): Observable<any> {
    let params = new HttpParams();

    if (search !== '') {
      params = params.append('search', search);
    }

    const requestOptions = { params: params };

    return this.http
      .get(`${environment.api.url}/${this.resource}/select`, requestOptions)
      .map((response: any) => response.data);
  }

  setIncludeList(includes: string | string[]) {
    includes = typeof includes === 'string' ? includes : includes.join(',');

    this.includeList += this.includeList === '' ? includes : ',' + includes;
  }

  setIncludeGet(includes: string | string[]) {
    includes = typeof includes === 'string' ? includes : includes.join(',');

    this.includeGet += this.includeGet === '' ? includes : ',' + includes;
  }
}
