import { FieldType } from '@ngx-formly/core';
import { Component } from '@angular/core';

@Component({
  selector: 'ngx-status',
  templateUrl: './status.component.html',
})
export class StatusComponent extends FieldType {
  ngOnInit() {
    if (this.to.value && !this.formControl.value) {
      this.formControl.setValue(this.to.value);
    }
  }
}
