import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Parsers } from '../../../utils/parsers';

@Component({
  selector: 'ngx-slug',
  templateUrl: 'slug.html',
})
export class SlugComponent extends FieldType {
  on = true;

  get type() {
    return this.to.type || 'text';
  }

  constructor() {
    super();
  }

  ngOnInit() {
    const bindControl = this.field.templateOptions.bindControl;

    if (bindControl) {
      this.form
        .get(bindControl)
        .valueChanges.debounceTime(500)
        .takeWhile(() => this.on && this.field.formControl.pristine)
        .subscribe(() => {
          this.field.formControl.setValue(this.form.get(bindControl).value);
        });
    }

    this.field.formControl.valueChanges
      .debounceTime(800)
      .takeWhile(() => this.on)
      .subscribe(() => {
        if (
          this.field.formControl.value !==
          Parsers.slug(this.field.formControl.value)
        ) {
          this.field.formControl.setValue(
            Parsers.slug(this.field.formControl.value),
          );
        }
      });
  }

  ngOnDestroy() {
    this.on = false;
  }
}
