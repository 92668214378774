import { Parsers } from './../../../utils/parsers';
import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Providers } from '../../../data/providers/providers';
import { _ } from 'underscore';
import 'rxjs/add/operator/takeWhile';

/**
 * Generated class for the CepComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'ngx-autocomplete',
  templateUrl: 'autocomplete.html',
})
export class AutoCompleteComponent extends FieldType {
  on = true;

  constructor() {
    super();
  }

  ngOnDestroy() {
    this.on = false;
  }

  blur(event) {
    const value = event.formControl.value;
    if (value && typeof value !== 'object') {
      event.formControl.setValue(null);
    }
  }

  displayFn(option) {
    return option ? option._display : null;
  }

  prepareItem(field, item) {
    if (item) {
      let title = item[field.templateOptions.optionTitle];
      if (field.templateOptions.optionTitleExpression) {
        title = eval(field.templateOptions.optionTitleExpression);
      }

      item._value = item[field.templateOptions.optionValue];
      item._title = title;

      let display = field.templateOptions.controlDisplay
        ? item[field.templateOptions.controlDisplay]
        : title;
      if (field.templateOptions.controlDisplayExpression) {
        display = eval(field.templateOptions.controlDisplayExpression);
      }
      item._display = display;
      return item;
    }
    return null;
  }

  ngOnInit() {
    this.field.parsers = [Parsers._value];
    const field = this.field;
    const model = this.model;
    field.formControl.valueChanges
      .debounceTime(500)
      .takeWhile(() => {
        return this.on;
      })
      .subscribe(() => {
        let value = this.field.formControl.value;

        if (this.field.formControl.pristine) {
          if (model[this.to.attribute]) {
            const item = this.prepareItem(
              this.field,
              model[this.to.attribute],
            );
            model[this.field.key] = item;
            this.field.formControl.setValue(item);
            value = item;
          }
        }

        if (value && typeof value === 'object') {
          this.to.options = [value];
        } else {
          const search = value ? value : '';
          Providers.get(this.to.resource)
            .list(1, search)
            .map(response => {
              return _.map(response.data, item => {
                return this.prepareItem(this.field, item);
              });
            })
            .subscribe(response => {
              this.to.options = response;
            });
        }
      });
  }

  // ngOnInit() {
  //     this.field.templateOptions.dataSource = Providers.get('classroom').list()
  //         .map((response) => {
  //             return response.data;
  //         });
  //     this.field.formControl.setValue(this.model['classroom'].name);
  // }

  // teste(event) {
  //     this.model[this.field.key] = event.item.id;
  // }
}
