import { environment } from './../environments/environment';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRoute } from '@angular/router';
import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { tap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Providers } from './@core/data/providers/providers';


@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: NbAuthService,
    private router: Router,
    public route: ActivatedRoute,
    public helper: JwtHelperService,
  ) { }

  canActivate() {
    return this.authService.isAuthenticated().pipe(
      tap(authenticated => {
        if (!authenticated) {
          this.router.navigate(['auth/login']);
        } else {
          const decodedToken = this.helper.decodeToken(localStorage.getItem(environment.token));
          const user = decodedToken.user;
          // REFACTOR
          if (user.username === 'guest') {
            this.router.navigate(['auth/login']);
            return;
          }

          Providers.get('user').get(user.id).subscribe(
            user => {
              if (user.force_password_change == true) {
                this.router.navigate(['/b/users/change_password/' + user.id]);
              }
            }
          );
        }
      }),
    );
  }
}
