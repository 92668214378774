import { COMPLEX_TYPES } from './types/complex';
import { BASIC_TYPES } from './types/basic';
import { USER_TYPES } from './types/user';
import { COURSE_TYPES } from './types/course';
import { CLASSROOM_TYPES } from './types/classroom';
import { GROUP_TYPES } from './types/group';

/**
 * Created by dayvisson on 30/06/17.
 */
export const FormlyTypes = [
  ...BASIC_TYPES,
  ...COMPLEX_TYPES,
  ...USER_TYPES,
  ...COURSE_TYPES,
  ...CLASSROOM_TYPES,
  ...GROUP_TYPES,
];
