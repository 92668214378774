import { config, lang } from '../../helpers';
import { Routes } from '../../../../node_modules/@angular/router';
import { SceneComponent } from '../../@theme/components/scene/scene.component';
import { ResourceBreadcrumbsResolver } from '../resolvers/resource-breadcrumbs-resolver';
import { PanelProvider } from '../data/providers/panel/panel';

export const RouteHelpers = {
  initRoutes(panel) {
    let routes = panel.config('routes');

    Object.keys(routes).forEach(k => {
      routes[k].core = RouteHelpers.parseRoutes(
        Object.values(routes[k].core),
        panel,
      );
    });
    PanelProvider.routes = routes;

    return true;
  },
  parseRoutes(data: string | any[], panel): Routes {
    let routes =
      typeof data === 'string' ? Object.values(panel.config(data)) : data;

    for (let route of routes) {
      route.data = route.data || {};
      if (!route.data.parsed) {
        RouteHelpers.parseScene(route);
        RouteHelpers.parseBreadcrumb(route, panel);
        RouteHelpers.parseChildren(route, panel);
        route.data.parsed = true;
      }
    }
    return routes;
  },
  parseChildren(route, panel) {
    if (route.children) {
      route.children = RouteHelpers.parseRoutes(route.children, panel);
    }
  },
  parseScene(route) {
    if (route.data && route.data.scene) {
      route.data.sceneConfig = route.data.scene;
      route.component = SceneComponent;
    }
  },
  parseBreadcrumb(route, panel) {
    const config = route.data && route.data.breadcrumbs;
    if (config) {
      if (config.provider) {
        route.data.breadcrumbs = ResourceBreadcrumbsResolver;
        route.data.breadcrumbLang = config.lang;
        route.data.breadcrumbProvider = config.provider;
      } else {
        route.data.breadcrumbs = panel.lang(config.lang);
      }
    }
  },
};
