import { Injectable } from '@angular/core';
import { BaseResourceProvider } from '../base-resource-provider';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

/*
  Generated class for the ClassroomProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class ClassroomRequestProvider extends BaseResourceProvider {
  constructor(http: HttpClient) {
    super(http, 'classroom_requests');
    this.includeList = 'course';
    this.includeGet = '';
  }
}
