import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';




export function Comment(target: any) {
  target.prototype.constructor(HttpClient);

  target.prototype.comment = function (item: any): Promise<any> {
    return this.http
      .post(
        `${environment.api.url}/${target.prototype.resource}/comments/${
        item.id
        }`,
        item,
    )
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(response => {
        return Promise.reject(response);
      });
  };

  target.prototype.destroyComment = function (item: any): Promise<any> {
    return this.http
      .delete(
        `${environment.api.url}/${this.resource}/comments/${item.id}/${
        item.commentId
        }`,
    )
      .toPromise()
      .then(response => {
        return true;
      })
      .catch(response => {
        return Promise.reject(response);
      });
  };

  target.prototype.patchComment = function (item: any): Observable<any> {
    return this.http.patch(
      `${environment.api.url}/${this.resource}/comments/${item.id}/${
      item.commentId
      }`,
      item,
    );
  };
}
