import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'ngx-wrapper-custom-label',
  template: `
    <div [class]="labelStyle">
    {{label}}
    </div>
    <div [class]="labelGroupStyle">
      <ng-container #fieldComponent></ng-container>
    </div>
  `,
})
// <div *ngxResetsOnly="">
export class CustomLabelComponent extends FieldWrapper {
  @ViewChild('fieldComponent', { read: ViewContainerRef })
  fieldComponent: ViewContainerRef;

  get label() {
    return this.to.label;
  }

  get labelStyle() {
    return this.to.labelStyle || '';
  }

  get labelGroupStyle() {
    return this.to.labelGroupStyle || '';
  }
}
