import { AsyncValidators } from './../async-validators';

export const USER_TYPES = [
  {
    name: 'unique_cpf',
    extends: 'cpf',
    defaultOptions: {
      asyncValidators: {
        unique: AsyncValidators.unique,
      },
    },
  },
  /* EMAIL */
  {
    name: 'email',
    extends: 'input',
    defaultOptions: {
      templateOptions: {
        type: 'email',
        label: 'Email',
        description: 'Digite o e-mail',
        required: true,
        resource: 'user',
      },
      asyncValidators: {
        unique: AsyncValidators.unique,
      },
      validators: {
        validation: ['email'],
      },
    },
  },
  /* EMAIL Not Unique */
  {
    name: 'email_duplicate',
    extends: 'input',
    defaultOptions: {
      templateOptions: {
        type: 'email',
        label: 'Email',
        description: 'Digite o e-mail',
        resource: 'user',
      },
      validators: {
        validation: ['email'],
      },
    },
  },
  /* EMAIL that exists */
  {
    name: 'email_exists',
    extends: 'input',
    defaultOptions: {
      templateOptions: {
        type: 'email',
        label: 'Email',
        description: 'Digite o e-mail',
        resource: 'user',
      },
      asyncValidators: {
        exists: AsyncValidators.exists,
      },
      validators: {
        validation: ['email'],
      },
    },
  },
  /* FISRTNAME */
  {
    name: 'firstname',
    extends: 'input',
    defaultOptions: {
      templateOptions: {
        label: 'Nome',
        description: 'Digite o primeiro nome',
        required: true,
      },
    },
  },
  /* LASTNAME */
  {
    name: 'lastname',
    extends: 'input',
    defaultOptions: {
      templateOptions: {
        label: 'Sobrenome',
        floatLabel: 'always',
        description: 'Digite o sobrenome',
        required: true,
      },
    },
  },
  /* USER */
  {
    name: 'user',
    extends: 'autocomplete',
    defaultOptions: {
      templateOptions: {
        label: 'Usuário',
        resource: 'user',
        required: true,
        optionValue: 'id',
        controlDisplay: 'fullname',
        optionTitleExpression: 'item.fullname + " ("+item.email+")"',
      },
    },
  },
  /* CARGO */
  {
    name: 'cargo',
    extends: 'input',
    defaultOptions: {
      templateOptions: {
        label: 'Cargo',
        description: 'Digite seu cargo',
        required: true,
      },
    },
  },
];
