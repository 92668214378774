import { Injectable } from '@angular/core';
import { BaseResourceProvider } from '../base-resource-provider';
import { HttpClient } from '@angular/common/http';
import { Comment } from '../../decorators/comment.decorator';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

/*
  Generated class for the GroupsProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
@Comment
export class GroupProvider extends BaseResourceProvider {
  constructor(http: HttpClient) {
    super(http, 'groups');
  }

  groupUsers(
    {
      id,
      page = 1,
      search = '',
      otherParams = {},
      filter = {},
      includes = '',
      orderBy = null,
      columns = null,
    }
  ): Observable<any> {
    let params = arguments[0];

    delete params.id;

    const requestOptions = this.parseParams(params);

    return this.http.get(`${environment.api.url}/groups-users/users/${id}`, requestOptions);
  }

  addMember({ id, role = '', user, otherParams = {} }): Observable<any> {
    const params = {
      group: id,
      role: user.role || role,
      ...otherParams,
    };

    params[user.id ? 'user_id' : 'user'] = user.id || user;

    return this.http.post(`${environment.api.url}/groups-users/user`, params);
  }

  changeRole({ id, role, user_id, otherParams = {} }) {
    return this.http.patch(`${environment.api.url}/groups-users/role`, {
      group: id,
      role: role,
      user_id: user_id,
      ...otherParams,
    });
  }

  removeMember({ id, user_id }) {
    return this.http.delete(`${environment.api.url}/groups-users/user/${id}/${user_id}`);
  }
}
