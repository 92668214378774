import { appContainer } from './../../../../app.container';
import { Component, ViewChild, ViewContainerRef, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'ngx-wrapper-permission',
  template: `
    <ng-container #fieldComponent></ng-container>
  `,
})
// <div *ngxPermissionsOnly="">
export class PermissionWrapperComponent extends FieldWrapper implements OnInit {
  @ViewChild('fieldComponent', { read: ViewContainerRef })
  fieldComponent: ViewContainerRef;

  constructor() {
    super();
    // this.fieldComponent..hideExpression = true;
  }

  ngOnInit() {
    const permissionService: NgxPermissionsService = appContainer().get(
      NgxPermissionsService,
    );

    permissionService
      .hasPermission([
        this.to.permission,
        this.to.permission + '@' + this.model.id,
      ])
      .then(has => {
        const shouldHide = this.to.hasOwnProperty('permissionHide') ? this.to.permissionHide : true;

        if (!has) {
          this.field.hideExpression = !has && shouldHide;
        }

        this.options.formState['has_' + this.to.permission] = has;

        if (this.to.noPermissionValue && !has) {
          this.formControl.setValue(this.to.noPermissionValue);
        }
      });
  }
}
