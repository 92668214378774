import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserService } from './users.service';
import { StateService } from './state.service';

import { ClassroomProvider } from './providers/classroom/classroom';
import { CourseProvider } from './providers/course/course';
import { EnrolmentProvider } from './providers/enrolment/enrolment';
import { UserEnrolmentProvider } from './providers/user-enrolment/user-enrolment';
import { UserProvider } from './providers/user/user';
import { GroupProvider } from './providers/group/group';
import { ClassroomRequestProvider } from './providers/classroom_request/classroom_request';

const SERVICES = [UserService, StateService];

const RESOURCE_PROVIDERS = [
  ClassroomProvider,
  ClassroomRequestProvider,
  CourseProvider,
  EnrolmentProvider,
  UserProvider,
  GroupProvider,
  UserEnrolmentProvider,
];

@NgModule({
  imports: [CommonModule],
  providers: [...SERVICES, ...RESOURCE_PROVIDERS],
})
export class DataModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: DataModule,
      providers: [...SERVICES, ...RESOURCE_PROVIDERS],
    };
  }
}
