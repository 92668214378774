import { Router } from '@angular/router';
import { FormlySceneComponent } from './../formly-scene/formly-scene.component';
import { FormGroup } from '@angular/forms';
import { langc, set_nested, lang, parseText, parseActionParams } from './../../../helpers';
import { Component, OnInit, Input } from '@angular/core';
import { defaultFormActionsTypes } from '../../../@core/utils/default.form.actions';

@Component({
  selector: 'form-actions',
  templateUrl: './form-actions.component.html',
  styleUrls: ['./form-actions.component.scss'],
})
export class FormActionsComponent implements OnInit {
  @Input()
  actions: Array<{
    title;
    icon?;
    type: string; // link, post, cancel, reset
    method?;
    toast?;
    redirect?;
    redirectTo?;
    params?;
    permission?;
    disableNotValid?;
    class?;
    button_type?;
    swal?;
    wrap_params?;
    changes?;
  }>;

  @Input() form: FormGroup;

  @Input() scene: FormlySceneComponent;

  @Input() model: any;

  constructor(private router: Router) { }

  ngOnInit() {
    this.actions.map(action => {
      if (Object.keys(defaultFormActionsTypes).includes(action.type)) {
        return defaultFormActionsTypes[action.type](action, this.model);
      }
    });
  }

  clickAction(action) {
    let parsedModel = JSON.parse(JSON.stringify(this.model));
    let parsedAction = JSON.parse(JSON.stringify(action));

    if (parsedAction.changes) {
      parsedAction.changes.forEach(change => {
        parsedModel = set_nested(parsedModel, change[0], parseText(change[1], parsedModel));
      });
    }

    if (parsedAction.params) {
      parsedAction.params = parsedAction.params === 'model' ? { parsedModel } : parseActionParams(parsedAction, parsedModel);
    }

    switch (parsedAction.type) {
      case 'post':
        this.scene.post(parsedAction);
        break;
      case 'cancel':
        this.scene.cancel(parsedAction);
        break;
      case 'reset':
        this.scene.reset();
        break;

      case 'link':
        this.router.navigate([parsedAction.link]);

      default:
        break;
    }
  }

  parseTitle(action, model) {
    return langc(action.title, 1, model);
  }

  parseSwal(action) {
    action.swal.title = lang(action.swal.title);
    return action.swal;
  }
}
