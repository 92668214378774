import { NgModule } from '@angular/core';

import { ThemeModule } from '../../../@theme/theme.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CardWrapperComponent } from './card/card.component';
import { PermissionWrapperComponent } from './permission/permission.component';
import { ResetWrapperComponent } from './reset/reset.component';
import { ClearSelf } from './clear-self/clear-self.component';
import { FirstOrCreate } from './first-or-create/first-or-create.component';
import { CustomLabelComponent } from './custom_label/custom_label.component';
import { BreakLineWrapperComponent } from './break-line/break-line.component';

const WRAPPERS_COMPONENTS = [
  CardWrapperComponent,
  PermissionWrapperComponent,
  ResetWrapperComponent,
  ClearSelf,
  FirstOrCreate,
  CustomLabelComponent,
  BreakLineWrapperComponent,
];

@NgModule({
  imports: [ThemeModule, NgbModule],
  declarations: [...WRAPPERS_COMPONENTS],
  exports: [...WRAPPERS_COMPONENTS],
})
export class WrappersModule { }
