import { Component, ViewChild, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ngx-wrapper-reset',
  template: `
    <ng-container #fieldComponent></ng-container>
  `,
})
// <div *ngxResetsOnly="">
export class ResetWrapperComponent extends FieldWrapper implements OnInit, OnDestroy {
  @ViewChild('fieldComponent', { read: ViewContainerRef })
  fieldComponent: ViewContainerRef;

  subscription: Subscription;

  ngOnInit() {
    if (this.to.reset) {
      const reset = this.to.reset;
      const reset_to = reset.keepOriginal && this.formControl.value || null;


      this.subscription = this.form.get(reset.field).valueChanges
        .debounceTime(500)
        .subscribe(value => {
          if (value == reset.value) {
            this.formControl.setValue(reset_to);
          }
        });
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
