import { registerPlugin } from './../../../@extra/filepond/filepond.component';
import { FormlyModule } from '@ngx-formly/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';

import {
  MatDatepickerModule,
  MatFormFieldModule,
  MatInputModule,
  MatAutocompleteModule,
} from '@angular/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { NgxSelectModule } from 'ngx-select-ex';

import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { DatepickerComponent } from './datepicker/datepicker';
import { InputmaskComponent } from './inputmask/inputmask';
import { AutoCompleteComponent } from './autocomplete/autocomplete';
import { SlugComponent } from './slug/slug';
import { TextComponent } from './text/text';
import { ThemeModule } from '../../../@theme/theme.module';
import { FirstOrCreateComponent } from './firstorcreate/firstorcreate';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingModule } from 'ngx-loading';
import { CommentComponent } from './comment/comment';
import { HiddenMapComponent } from './hiddenmap/hiddenmap';
import { PasswordComponent } from './password/password';

import { FilePondComponent } from './filepond/filepond';
import { NgxPermissionsModule } from 'ngx-permissions';

import * as FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import * as FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import * as FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import * as FilePondPluginFileImagePreview from 'filepond-plugin-image-preview';
import { RepeatTypeComponent } from './repeat/repeat';
import { FilePondModule } from '../../../@extra/filepond/angular-filepond.module';
import { StatusComponent } from './status/status.component';
import { LinkComponent } from './link/link';
import { ImportField } from './import/import-field';
import { TableComponent } from './table/table.component';
import { SearchComponent } from './search/search.component';
import { SelectTableComponent } from './select-table/select-table.component';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { FilterGroupComponent } from './filter-group/filter-group.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { AnalyticsComponent } from './analytics/analytics.component';

registerPlugin(FilePondPluginFileValidateSize);
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginImageCrop);
registerPlugin(FilePondPluginFileImagePreview);

const FIELDS_COMPONENTS = [
  DatepickerComponent,
  InputmaskComponent,
  FirstOrCreateComponent,
  AutoCompleteComponent,
  SlugComponent,
  TextComponent,
  CommentComponent,
  HiddenMapComponent,
  PasswordComponent,
  FilePondComponent,
  RepeatTypeComponent,
  StatusComponent,
  LinkComponent,
  ImportField,
  TableComponent,
  SearchComponent,
  SelectTableComponent,
  MultiSelectComponent,
  FilterGroupComponent,
  CheckboxComponent,
  AnalyticsComponent,
];

@NgModule({
  imports: [
    ReactiveFormsModule,
    FormlyModule.forChild(),
    CommonModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    TextMaskModule,
    TypeaheadModule,
    ThemeModule,
    LoadingModule,
    NgbModule,
    FilePondModule,
    NgxPermissionsModule.forChild(),
    NgxSelectModule,
  ],
  declarations: [...FIELDS_COMPONENTS],
  exports: [...FIELDS_COMPONENTS],
})
export class FieldsModule {}
