import { appContainer } from './app.container';
import { PanelProvider } from './@core/data/providers/panel/panel';
import { AuthProvider } from './@core/data/providers/auth/auth';
import { FieldType, FieldWrapper } from '@ngx-formly/core';
import { Keys } from '@swimlane/ngx-datatable/release/utils';
import { PluckKey } from './@core/data/interfaces/pluck-key';
export const lang = (key: string | any[], params = {}): string => {
  const panelProvider = appContainer().get(PanelProvider);

  if (typeof key === 'string') {
    return panelProvider.lang(key, params);
  }

  return panelProvider.lang_choice(key[0], key[1], params);
};

export const langc = (key: string, count: number, params = {}): string => {
  const panelProvider = appContainer().get(PanelProvider);
  return panelProvider.lang_choice(key, count, params);
};

export const config = (key: string): any => {
  const panelProvider = appContainer().get(PanelProvider);
  return panelProvider.config(key);
};

export const user = () => {
  const authProvider = appContainer().get(AuthProvider);
  return authProvider.user();
};

export const refreshUser = () => {
  const authProvider = appContainer().get(AuthProvider);
  return authProvider.setUser();
};

export const removeData = (model: any, deep = 0, maxdeep = 1) => {
  if (deep > maxdeep) {
    return model;
  }

  if (!isObject(model)) {
    return model;
  }

  if (Array.isArray(model.data)) {
    model = model.data;
  }
  const keys = Object.keys(model);
  for (const r of keys) {
    model[r] = removeData(model[r], deep + 1, maxdeep);
  }

  return model;
};

export const isObject = val => {
  if (val === null) {
    return false;
  }
  return typeof val === 'function' || typeof val === 'object';
};

export const tableResourceLinks = (resource, action = null) => {
  if (action) {
    return '/p/' + resource + '/' + action;
  }
  return {
    create: '/p/' + resource + '/create',
    edit: '/p/' + resource + '/edit',
    show: '/p/' + resource + '/show',
    import: '/p/' + resource + '/import',
  };
};

export const tablePermissions = (resource, action = null) => {
  if (action) {
    return action + ':' + resource;
  }
  return {
    create: 'create:' + resource,
    edit: 'edit:' + resource,
    delete: 'delete:' + resource,
    show: 'show:' + resource,
    import: 'import:' + resource,
  };
};

export const parseFilters = (target: FieldType | FieldWrapper) => {
  if (target.field.templateOptions.filters) {
    const filter = {
      parsed: {},

      assign: function (param) {
        Object.assign(this.parsed, param);
      },

      /**
       * If the value of the filter is an array of objects
       * the parser will try to find an element within
       * each objet using the filter field as the key and
       * concatenate each value into a string divided by
       * a comma.
       */
      set value(param) {
        const hasModelField = param.hasOwnProperty('modelField');

        if (Array.isArray(param.value)) {
          let str = '';

          param.value.forEach(element => {
            if (typeof element === 'object' && hasModelField) {
              str += str === '' ? element[param.modelField] :
                ',' + element[param.modelField];
              return;
            }

            str += str === '' ? element : ',' + element;
          });

          param.value = str;
        }

        this.parsed[param.field] = param.value;
      }
    };

    target.field.templateOptions.filters.forEach(element => {
      if (typeof element === 'string') {
        const value = target.form.get(element).value;

        if (value) {
          if (typeof value === 'object') {
            filter.assign(value);
          } else {
            filter.value = { field: element, value: value };
          }
        }

        return;
      }

      if (element.control) {
        filter.value = { value: target.form.get(element.control).value, ...element };
        return;
      }

      if (element.value) {
        filter.value = { value: element.value, ...element };
        return;
      }

      if (element.model) {
        filter.value = { value: __nested(target.model, element.model), ...element };
        return;
      }

      if (element.find) {
        filter.value = { value: __nested(target, element.find), ...element }
        return;
      }

    });

    return filter.parsed;
  }
};

export const parseText = function (expression: string, obj: object, separator = ':') {
  return expression.replace(new RegExp(separator + '([\\w.]+)', 'g'), (match, p1) => {
    return __nested(obj, p1);
  });
}

export const __nested = function (obj: object, path: string) {
  return path !== '' ? __nested(obj[/^\w+/.exec(path)[0]], path.replace(/^\w+\.?/, '')) : obj;
}

export const delete_prop = function (obj: object, path: string) {
  if (!/\./.test(path)) {
    delete obj[path];
    return true;
  }

  return delete_prop(obj[/^\w+/.exec(path)[0]], path.replace(/^\w+\.?/, ''));
}

export const pluck = function (obj: object, keys: string[]) {
  const filtered = {};
  const pattern = /(\w+)\|(\w+)/;

  keys.forEach(element => {
    const attribute = element.replace(pattern, '$1');
    const _as = element.replace(pattern, '$2').replace(/.+\.(\w+)/, '$1');

    if (obj_has(obj, attribute)) {
      filtered[_as] = __nested(obj, attribute);
    }
  });

  return filtered;
}

export const obj_has = function (obj: any, path: string) {
  if (typeof obj !== 'object') {
    return path === '' && obj !== undefined;
  }

  return path !== '' ? obj_has(obj[/^\w+/.exec(path)[0]], path.replace(/^\w+\.?/, '')) : obj !== undefined;
}

export const set_nested = function (obj: object, path: string, value: any) {
  const att = /^\w+/.exec(path)[0];
  const replace = path.replace(/^\w+\.?/, '');

  obj[att] = replace === '' ? value : set_nested(obj[att] || {}, replace, value);

  return obj;
}

export const flatten = function (obj: object, depth: number = 0) {
  const flatened = {};

  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      Object.assign(flatened, depth > 0 ? flatten(obj[key], depth - 1) : obj[key]);
      continue;
    }

    flatened[key] = obj[key];
  }

  return flatened;
}

export const renameProp = function (obj: object, oldName: string, newName: string) {
  if (obj.hasOwnProperty(oldName)) {
    Object.defineProperty(obj, newName, Object.getOwnPropertyDescriptor(obj, oldName));
    delete obj[oldName];
  }

  return obj;
}

export const renameProps = function (obj: object, props: string[]) {
  const descriptors = {};

  props.forEach(prop => {
    let porpNames = prop.split('|');

    descriptors[porpNames[1]] = Object.getOwnPropertyDescriptor(obj, porpNames[0]);
    delete obj[porpNames[0]];
  });

  return Object.defineProperties(obj, descriptors);
}

export const parseActionParams = function (action: { params: any, wrap_params?: boolean }, model: object) {
  const parsed: any = {};

  if (action.params.model && Array.isArray(action.params.model)) {
    Object.assign(parsed, pluck(model, action.params.model));
    delete action.params.model;
  }

  if (action.params.otherParams) {
    parsed.otherParams = Object.assign({}, parseActionParams({ params: action.params.otherParams }, model));
    delete action.params.otherParams;
  }

  if (action.params.rename) {
    Object.assign(parsed, renameProps(model, action.params.rename));
    delete action.params.rename;
  }

  Object.assign(parsed, action.params);

  return action.wrap_params ? { wraped: parsed } : parsed;
}