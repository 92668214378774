import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'ngx-hiddenmap',
  templateUrl: 'hiddenmap.html',
})
export class HiddenMapComponent extends FieldType {
  on = true;

  constructor() {
    super();
  }

  ngOnInit() {
    const map = this.field.templateOptions.map;

    if (map) {
      const control = this.form.get(map);

      setTimeout(() => {
        const parsers = this.options.formState.activeParsers;

        if (parsers && parsers.hasOwnProperty(map)) {
          this.field.parsers = parsers[map];
        }
      }, 500);


      if (this.to.initToSelf) {
        control.setValue(this.formControl.value);
      }

      control.valueChanges
        .debounceTime(100)
        .takeWhile(() => this.on)
        .subscribe(() => {
          this.field.formControl.setValue(control.value);
        });
    }

  }

  ngOnDestroy() {
    this.on = false;
  }
}
