import { Component, OnDestroy } from '@angular/core';
import {
  NbMediaBreakpoint,
  NbMediaBreakpointsService,
  NbMenuItem,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme';

import { StateService } from '../../../@core/data/state.service';

import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/withLatestFrom';
import 'rxjs/add/operator/delay';
import { config } from '../../../helpers';

// TODO: move layouts into the framework
@Component({
  selector: 'ngx-pdj-layout',
  styleUrls: ['./pdj.layout.scss'],
  template: `
    <nb-layout [center]="layout.id === 'center-column'">
      <nb-layout-header fixed>
        <ngx-header [position]="sidebar.id === 'start' ? 'normal': 'inverse'"></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar"
                   tag="menu-sidebar"
                   responsive
                   [right]="sidebar.id === 'right'">
        <nb-sidebar-header>
          <a href="#" class="main-btn">
            <img class="logo" [src]="logo">
            <img class="logosm" [src]="logosm">
          </a>
        </nb-sidebar-header>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column class="main-content">
        <mc-breadcrumbs></mc-breadcrumbs>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-column left class="small" *ngIf="layout.id === 'two-column' || layout.id === 'three-column'">
        <nb-menu [items]="subMenu"></nb-menu>
      </nb-layout-column>

      <nb-layout-column right class="small" *ngIf="layout.id === 'three-column'">
        <nb-menu [items]="subMenu"></nb-menu>
      </nb-layout-column>

      <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>


    </nb-layout>
  `,
})
// <nb-sidebar class="settings-sidebar"
//              tag="settings-sidebar"
//              state="collapsed"
//              fixed
//              [right]="sidebar.id !== 'right'">
//   <ngx-theme-settings></ngx-theme-settings>
// </nb-sidebar>
export class PdjLayoutComponent implements OnDestroy {

  layout: any = {};
  sidebar: any = {};
  logo: string;
  logosm: string;

  protected layoutState$: Subscription;
  protected sidebarState$: Subscription;
  protected menuClick$: Subscription;

  constructor(
    protected stateService: StateService,
    protected menuService: NbMenuService,
    protected themeService: NbThemeService,
    protected bpService: NbMediaBreakpointsService,
    protected sidebarService: NbSidebarService,
  ) {


    this.logo = config('core.logo');
    this.logosm = config('core.logosm');

    this.layoutState$ = this.stateService
      .onLayoutState()
      .subscribe((layout: string) => {
        (this.layout = layout);
      });

    this.sidebarState$ = this.stateService
      .onSidebarState()
      .subscribe((sidebar: string) => {
        this.sidebar = sidebar;
      });

    const isBp = this.bpService.getByName('is');
    this.menuClick$ = this.menuService
      .onItemSelect()
      .withLatestFrom(this.themeService.onMediaQueryChange())
      .delay(20)
      .subscribe(
        ([item, [bpFrom, bpTo]]: [
          any,
          [NbMediaBreakpoint, NbMediaBreakpoint]
        ]) => {
          if (bpTo.width <= isBp.width) {
            this.sidebarService.collapse('menu-sidebar');
          }
        },
    );
  }

  ngOnDestroy() {
    this.layoutState$.unsubscribe();
    this.sidebarState$.unsubscribe();
    this.menuClick$.unsubscribe();
  }
}
