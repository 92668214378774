import { FormControl, ValidationErrors } from '@angular/forms';
import * as CPF_CNPJ from 'cpf_cnpj';

/**
 * Created by dayvisson on 30/06/17.
 */
export const FormlyValidators = [
  { name: 'cep', validation: cepValidator },
  { name: 'cpf', validation: cpfValidator },
  { name: 'email', validation: emailValidator },
  { name: 'opt_email', validation: optionalEmailValidator },
  { name: 'cnpj', validation: cnpjValidator },
  { name: 'url', validation: urlValidatior },
];

export function cpfValidator(control: FormControl): ValidationErrors {
  const pattern = /\d{3}.?\d{3}.?\d{3}.?\d{2}/.test(control.value);
  const valid = CPF_CNPJ.CPF.isValid(control.value);
  return pattern && valid ? null : { cpf: true };
}

export function cnpjValidator(control: FormControl): ValidationErrors {
  const pattern = /\d{2}.?\d{3}.?\d{3}\/?\d{4}-?\d{2}/.test(control.value);
  const valid = CPF_CNPJ.CNPJ.isValid(control.value);
  return pattern && valid ? null : { cnpj: true };
}

export function cepValidator(control: FormControl) {
  const pattern = /\d{5}-?\d{3}/.test(control.value);
  return pattern ? null : { cep: true };
}

export function emailValidator(control: FormControl) {
  const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    control.value,
  );
  return pattern ? null : { email: true };
}

export function optionalEmailValidator(control: FormControl) {
  return !control.value ? null : emailValidator(control);
}

export function urlValidatior(control: FormControl) {
  if (control.value) {
    const pattern = /^(?:s?f|ht)tps?:\/\/\w[^\s]+$/.test(control.value);
    return pattern ? null : { url: true };
  }
  return null;
}
