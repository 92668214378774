import { langc, lang } from './../../helpers';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'lang',
})
export class LangPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(
    value: any,
    key: string,
    count: number = null,
    params: any = null,
    lcomp: boolean = false,
  ): any {
    if (value) {
      let content;
      key = lcomp ? key + '.' + value : key;
      if (count) {
        content = langc(key, count, params);
      }
      content = lang(key, params);
      return this.sanitizer.bypassSecurityTrustHtml(content);
    }
  }
}
