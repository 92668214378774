import { Component } from '@angular/core';
import { FieldArrayType, FormlyFormBuilder } from '@ngx-formly/core';

@Component({
  selector: 'ngx-formly-repeat-section',
  templateUrl: 'repeat.html',
})
export class RepeatTypeComponent extends FieldArrayType {
  empty = false;

  constructor(builder: FormlyFormBuilder) {
    super(builder);
  }

  ngOnInit() {
    this.formControl.valueChanges.debounceTime(500).subscribe(() => {
      this.empty = this.model.length === 0;
    });
  }
}
