import { PdjLayoutComponent } from './layouts/pdj/pdj.layout';
import { LoadingModule } from 'ngx-loading';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { FormlyModule } from '@ngx-formly/core';
import { LogoutComponent } from './components/auth/logout/logout.component';
import { LoginComponent } from './components/auth/login/login.component';
import { AuthBlockComponent } from './components/auth/auth-block/auth-block.component';
import { AuthComponent } from './components/auth/auth.component';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TableComponent } from './components/resource/table/table.component';
import { FormComponent } from './components/resource/form/form.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import {
  NbActionsModule,
  NbCardModule,
  NbLayoutModule,
  NbMenuModule,
  NbRouteTabsetModule,
  NbSearchModule,
  NbSidebarModule,
  NbTabsetModule,
  NbThemeModule,
  NbUserModule,
  NbCheckboxModule,
  NbPopoverModule,
  NbContextMenuModule,
} from '@nebular/theme';

import { NbSecurityModule } from '@nebular/security';

import {
  FooterComponent,
  HeaderComponent,
  SearchInputComponent,
  ThemeSettingsComponent,
  SwitcherComponent,
  LayoutDirectionSwitcherComponent,
  ThemeSwitcherComponent,
  ExtHeaderComponent,
} from './components';
import {
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  Formatter,
  ParseT,
} from './pipes';
import {
  OneColumnLayoutComponent,
  SampleLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
} from './layouts';
import { DEFAULT_THEME } from './styles/theme.default';
import { COSMIC_THEME } from './styles/theme.cosmic';
import { ToasterModule } from 'angular2-toaster';
import { McBreadcrumbsModule } from 'ngx-breadcrumbs';
import { formattedError } from '@angular/compiler';
import { TableActionsComponent } from './components/table-actions/table-actions.component';
import { LangPipe } from './pipes/lang.pipe';
import { FormlySceneComponent } from './components/formly-scene/formly-scene.component';
import { CardActionsComponent } from './components/card-actions/card-actions.component';
import { FormActionsComponent } from './components/form-actions/form-actions.component';
import { SceneComponent } from './components/scene/scene.component';

const BASE_MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
];

const NB_MODULES = [
  NbCardModule,
  NbLayoutModule,
  NbTabsetModule,
  NbRouteTabsetModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbCheckboxModule,
  NbPopoverModule,
  NbContextMenuModule,
  NgbModule,
  NbSecurityModule, // *nbIsGranted directive
];

const OTHER_MODULES = [
  FormlyModule,
  NgxDatatableModule,
  SweetAlert2Module,
  LoadingModule,
  ToasterModule,
  McBreadcrumbsModule,
  NgxPermissionsModule,
  NgxChartsModule,
];

const COMPONENTS = [
  SwitcherComponent,
  LayoutDirectionSwitcherComponent,
  ThemeSwitcherComponent,
  HeaderComponent,
  FooterComponent,
  SearchInputComponent,
  ThemeSettingsComponent,
  OneColumnLayoutComponent,
  SampleLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
];

const PDJ_COMPONENTS = [
  PdjLayoutComponent,
  ExtHeaderComponent,
  AuthComponent,
  AuthBlockComponent,
  LoginComponent,
  LogoutComponent,
  TableComponent,
  FormComponent,
  TableActionsComponent,
  CardActionsComponent,
  FormlySceneComponent,
  FormActionsComponent,
  SceneComponent
];

const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  Formatter,
  LangPipe,
  ParseT,
];

const NB_THEME_PROVIDERS = [
  ...NbThemeModule.forRoot(
    {
      name: 'default',
    },
    [DEFAULT_THEME, COSMIC_THEME],
  ).providers,
  ...NbSidebarModule.forRoot().providers,
  ...NbMenuModule.forRoot().providers,
];

@NgModule({
  imports: [...BASE_MODULES, ...NB_MODULES, ...OTHER_MODULES],
  exports: [
    ...BASE_MODULES,
    ...NB_MODULES,
    ...COMPONENTS,
    ...PDJ_COMPONENTS,
    ...PIPES,
    ...OTHER_MODULES,
  ],
  declarations: [...COMPONENTS, ...PDJ_COMPONENTS, ...PIPES],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: ThemeModule,
      providers: [...NB_THEME_PROVIDERS],
    };
  }
}
