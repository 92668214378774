import { Providers } from './../../../data/providers/providers';
import { NgxPermissionsService } from 'ngx-permissions';
import {
  config,
  tableResourceLinks,
  tablePermissions,
  parseFilters,
  parseText,
  parseActionParams,
} from './../../../../helpers';
import { FieldType } from '@ngx-formly/core';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { BaseResourceProvider } from '../../../data/providers/base-resource-provider';
import { defaultActionsTypes } from '../../../utils/default.table.actions';
import { Observable } from 'rxjs';

@Component({
  selector: 'ngx-table-field',
  templateUrl: './table.component.html',
})
export class TableComponent extends FieldType {
  /**
   *  templateOptions:
   *  resource : Resource name
   *  provider:  Providers key
   *  columns: config key to columns
   *  endpoint: function(page: number,search : string, otherParams: {},filter: {})
   */

  @ViewChild('actionsDefaultTemplate') actionsDefaultTemplate: TemplateRef<any>;
  @ViewChild('cellTemplate') cellTemplate: TemplateRef<any>;
  rows: any;
  columns: Array<any>;
  actions: Array<any>;
  actionTemplate: TemplateRef<any> = null;

  provider: BaseResourceProvider;

  meta: any;

  page: number;
  search: string;
  otherParams: any = {};

  hasMoreItems: boolean;
  loading: boolean = true;

  permissions: any;
  links: any;

  endpoint: string = 'list';

  externalPaging: boolean;

  scrollbarH: boolean;
  scrollbarV: boolean;

  __this: any;

  constructor(private permissionService: NgxPermissionsService) {
    super();
  }

  ngOnInit() {
    this.__this = this;
    this.links = tableResourceLinks(this.to.resource);
    this.permissions = tablePermissions(this.to.resource);
    this.endpoint = this.to.endpoint || this.endpoint;
    this.provider =
      this.to.provider instanceof BaseResourceProvider
        ? this.to.provider
        : Providers.get(this.to.provider);
    this.otherParams = this.to.otherParams || {};
    this.externalPaging = this.to.externalPaging || true;
    this.scrollbarH = this.to.scrollbarH || false;
    this.scrollbarV = this.to.scrollbarV || false;
    this.prepActions();
    this.prepColumns();

    this.activateFilters();
    this.searchItems();
    this.getList();
  }

  prepActions() {
    this.actions = this.to.actions || [];
    this.actions.forEach(element => {
      if (defaultActionsTypes[element.type]) {
        defaultActionsTypes[element.type](element, this.to.resource, this);
      }
    });
  }

  prepColumns() {
    this.columns =
      typeof this.to.columns === 'string'
        ? JSON.parse(JSON.stringify(config(this.to.columns)))
        : this.to.columns;

    this.columns.forEach(item => {
      item.cellTemplate = this.cellTemplate;
    });

    const permissions = [];
    for (const permission of this.permissions) {
      permissions.push(permission);
    }
    if (
      (!this.permissions ||
        this.permissionService.hasPermission(permissions)) &&
      this.actions &&
      this.actions.length > 0
    ) {
      this.columns.push({
        name: 'Ações',
        prop: 'id',
        maxWidth: 200,
        cellTemplate: this.actionTemplate || this.actionsDefaultTemplate,
        sortable: false,
      });
    }
  }

  activateFilters() {
    if (this.to.filters) {
      this.to.filters.forEach(element => {
        let control = element,
          field = element;
        if (typeof element !== 'string') {
          control = element.control;
          field = element.field;
        }
        const filterControl = this.form.get(control);
        if (filterControl) {
          filterControl.valueChanges.debounceTime(1000).subscribe(() => {
            this.reset();
            this.getList();
          });
        }
      });
    }

    if (this.to.filter_conditions) {
      let value = '';

      this.to.filter_conditions.forEach(condition => {
        const concat = condition[0] + ':' + condition[1];
        value += value === '' ? concat : ';' + concat;
      });

      this.otherParams.filterconditions = value;
    }
  }

  getList() {
    this.loading = true;

    if (this.to.json_rows) {
      this.rows = this.to.json_rows.data;
      this.meta = this.to.json_rows.meta;
      this.formControl.setValue(this.rows);
      this.loading = false;

      return true;
    }

    this.fetch().subscribe(
      response => {
        this.rows = response.data;
        this.meta = response.meta;
        this.loading = false;
        this.formControl.setValue(this.rows);
      },
      () => {
        this.loading = false;
      },
    );
  }

  fetch(): Observable<any> {
    return this.provider[this.endpoint]({
      page: this.page,
      search: this.search,
      otherParams: this.otherParams,
      filter: parseFilters(this),
      includes: this.to.includes
        ? parseText(this.to.includes, this.model, '!:')
        : null,
      id: this.model.id,
    });
  }

  searchItems() {
    if (this.to.search) {
      const searchControl = this.form.get(this.to.search);
      if (searchControl) {
        searchControl.valueChanges.debounceTime(1000).subscribe(() => {
          this.search = searchControl.value || '';
          this.reset();
          this.getList();
        });
      }
    } else {
      this.getList();
    }
  }

  reset() {
    this.page = 1;
    this.hasMoreItems = true;
  }

  onSort(event) {
    const sort = event.sorts[0];
    const pattern = new RegExp('^_');
    this.otherParams = {
      orderBy: sort.prop.replace(pattern, ''),
      sortedBy: sort.dir,
    };
    this.getList();
  }

  setPage(event) {
    this.page = event.offset + 1;
    this.getList();
  }

  public run(__this, row, action) {
    __this.provider[action.method]
      .apply(__this.provider, Object.values(parseActionParams(action, row)))
      .toPromise()
      .then(() => {
        __this.getList();
      });
  }
}
