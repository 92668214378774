import { Component, ViewChild, ViewContainerRef, OnInit, OnDestroy, AfterViewInit, AfterContentInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { pluck, parseText, set_nested, __nested, delete_prop, parseFilters, obj_has } from '../../../../helpers';
import { Providers } from '../../../data/providers/providers';
import { BaseResourceProvider } from '../../../data/providers/base-resource-provider';

@Component({
  selector: 'ngx-wrapper-first-or-create',
  templateUrl: './first-or-create.component.html',
})
// <div *ngxResetsOnly="">
export class FirstOrCreate extends FieldWrapper implements OnInit, OnDestroy {
  @ViewChild('fieldComponent', { read: ViewContainerRef })
  fieldComponent: ViewContainerRef;

  subscription: Subscription;

  endpoint = '';
  provider: BaseResourceProvider;
  params: object;
  prop: string[];
  target: string = '';
  otherParams;
  setCondition: { key, value } | boolean = true;
  entryHistory: [string, any][] = [];
  created: boolean = false;
  validation: string[];
  searchTargets = () => {
    return {
      model: this.model,
      response: this.options.formState.firstOrCreate_response,
    }
  }

  ngOnInit() {
    if (this.to.firstOrCreate) {
      this.provider = Providers.get(this.to.firstOrCreate.provider);
      this.endpoint = this.to.firstOrCreate.endpoint || 'exists';
      this.prop = this.to.firstOrCreate.prop || [];
      this.target = this.to.firstOrCreate.target || '';
      this.otherParams = this.to.firstOrCreate.otherParams || {};
      this.setCondition = this.to.firstOrCreate.hasOwnProperty('setCondition') ?
        this.to.firstOrCreate.setCondition : this.setCondition;
      this.validation = this.to.firstOrCreate.validation;

      this.options.formState.firstOrCreate_state = this.to.firstOrCreate_state || 'first';
      this.options.formState.firstOrCreate_response = {};

      if (this.otherParams.filter) {
        const oldFilters = this.to.filters || null;

        this.field.templateOptions.filters = this.otherParams.filter;
        this.otherParams.filter = parseFilters(this);

        if (oldFilters) {
          this.field.templateOptions.filters = oldFilters;
        } else {
          delete this.field.templateOptions.filters;
        }
      }

      this.subscription = this.formControl.statusChanges
        .subscribe((status) => {
          if (this.formControl.valid) {
            this.fetch();
          } else {
            this.cleanup(true);
            this.options.formState.firstOrCreate_state = 'first';
          }
        });
    }
  }

  parseParams(params) {
    const parsed = {};

    params.forEach(param => {
      const value = /^:this:/.test(param.value) ?
        __nested(this.model, this.field.key) : parseText(param.value, this.model);

      parsed[param.field] = value;
    });

    return parsed;
  }

  get parseTarget() {
    return this.searchTargets()[this.to.firstOrCreate.parseTarget || 'model'];
  }

  fetch() {
    if (this.to.firstOrCreate.params) {
      this.params = this.parseParams(this.to.firstOrCreate.params);
    } else {
      this.params = {
        field: 'username',
        value: __nested(this.model, this.field.key),
      };
    }

    this.provider[this.endpoint]({ ...this.params, ...this.otherParams })
      .subscribe(response => {
        this.options.formState.firstOrCreate_response = response;
        this.transform(response, this.target, this.prop, this.setCondition);

        this.options.formState.firstOrCreate_state = this.cleanup() ? 'create' : 'first';
      });
  }

  cleanup(force = false) {
    const response = this.options.formState.firstOrCreate_response || false;
    let value = this.to.firstOrCreate.nestedEmpty;

    value = value && response ? __nested(response, value) : response;

    if ((value === this.to.firstOrCreate.emptyValue || force)) {
      if (this.created) {
        this.entryHistory.forEach(entry => {
          if (entry[1]) {
            set_nested(this.model, entry[0], entry[1]);
          } else {
            delete_prop(this.model, entry[0]);
          }
        });

        this.entryHistory = [];

        this.created = false;
      }

      return true;
    }

    return false;
  }

  transform(response, target = '', prop = [], setCondition: { key, value } | boolean = true) {
    const shouldSet = typeof setCondition === 'object' ?
      __nested(response, setCondition.key) === setCondition.value : setCondition;

    if (shouldSet) {
      prop = prop.length ? pluck(response, prop) : response;
      target = target || this.model;

      if (typeof target === 'string') {
        this.entryHistory.push([target, obj_has(this.model, target) ? __nested(this.model, target) : undefined]);
        set_nested(this.model, target, prop);
      } else {
        const _keys = Object.keys(this.model);

        this.entryHistory = Object.keys(prop).map((key): [string, any] => {
          return _keys.includes(key) ? [key, this.model[key]] : [key, undefined];
        });

        Object.assign(this.model, prop);
      }

      this.created = true;
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
