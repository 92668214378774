/**
 * Created by dayvisson on 30/06/17.
 */
export const FormlyValidationMessages = [
  { name: 'required', message: 'Campo obrigatório!' },
  { name: 'cep', message: 'Formato de cep inválido, utilize 00000-000.' },
  { name: 'cpf', message: 'CPF inválido!' },
  { name: 'cnpj', message: 'CNPJ inválido!' },
  { name: 'email', message: 'E-mail inválido!' },
  { name: 'pattern', message: 'Formato inválido!' },
  { name: 'url', message: 'Endereço inválido!' },
  { name: 'min', message: minValidationMessage },
  { name: 'max', message: maxValidationMessage },
  { name: 'matDatepickerMin', message: minDateValidationMessage },
  { name: 'matDatepickerMax', message: maxDateValidationMessage },
  { name: 'invalid', message: "Valor inválido!" },
];

export function minDateValidationMessage(err, field) {
  field.templateOptions.minDate.locale('pt-br');
  return `A data deve ser maior que ${field.templateOptions.minDate.format(
    'LL',
  )}.`;
}

export function maxDateValidationMessage(err, field) {
  field.templateOptions.maxDate.locale('pt-br');
  return `A data deve ser menor que ${field.templateOptions.maxDate.format(
    'LL',
  )}.`;
}
export function minValidationMessage(err, field) {
  return `O valor deve ser maior que ${field.templateOptions.min}`;
}

export function maxValidationMessage(err, field) {
  return `O valor deve ser menor que ${field.templateOptions.max}`;
}
