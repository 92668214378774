import { Component, ViewChild, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { delete_prop } from '../../../../helpers';

@Component({
  selector: 'ngx-wrapper-clear-self',
  template: `
    <ng-container #fieldComponent></ng-container>
  `,
})
// <div *ngxResetsOnly="">
export class ClearSelf extends FieldWrapper implements OnInit, OnDestroy {
  @ViewChild('fieldComponent', { read: ViewContainerRef })
  fieldComponent: ViewContainerRef;

  subscription: Subscription;

  ngOnInit() {
    const emptyValaue = this.to.hasOwnProperty('emptyValue') ? this.to.emptyValue : '';
    const field = this.to.emptyValueField ? this.form.get(this.to.emptyValueField) : this.formControl;

    this.subscription = field.valueChanges
      .subscribe((value) => {
        if (value === emptyValaue) {
          delete_prop(this.model, this.field.key);
        }
      });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
