import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import {
  RouterStateSnapshot,
  CanActivateChild,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { AuthProvider } from './@core/data/providers/auth/auth';

@Injectable()
export class AuthGuardPermissions implements CanActivateChild {
  constructor(public auth: AuthProvider) {}

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (route.data.permissionsComponent) {
      return this.auth
        .loadPermissionsByComponent(route.data.permissionsComponent)
        .map(() => {
          return true;
        });
    }
    return true;
  }
}
