import { JwtHelperService } from '@auth0/angular-jwt';
import { NgxPermissionsService } from 'ngx-permissions';
import { environment } from './../../../../../environments/environment';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { NbAuthService } from '@nebular/auth';
import { HttpClient } from '@angular/common/http';
/*
  Generated class for the PanelProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class AuthProvider {
  _user: any = null;
  _permissions: any = [];
  refreshPermissionTime: 60000;

  constructor(public authHttp: HttpClient, public injector: Injector) {
    this.setUser();
  }

  setUser() {
    const helper = this.injector.get(JwtHelperService);
    const decodedToken = helper.decodeToken(
      localStorage.getItem(environment.token),
    );
    // here we receive a payload from the token and assigne it to our `user` variable
    this._user = decodedToken ? decodedToken.user : null;
  }
  user() {
    return this._user;
  }

  loadPermissions(permissions: string[]): Observable<string[]> {
    return this.authHttp
      .get(environment.api.url + '/panel/permissions', {
        params: {
          permissions: permissions,
        },
      })
      .map(response => {
        return ['teste'];
      });
  }

  flushPermissions() {
    const permissionService = this.injector.get(NgxPermissionsService);
    permissionService.flushPermissions();
  }

  loadPermissionsByComponent(component: string): Observable<any> {
    if (!this.isValidPermissions(component)) {
      return this.authHttp
        .post(environment.api.url + '/panel/component/permissions', {
          component: component,
        })
        .map(response => {
          const permissions: string[] = Object.values(response);
          const permissionService = this.injector.get(NgxPermissionsService);
          permissionService.addPermission(permissions);
          this.setPermission(permissions, component);
          return permissions;
        });
    }
    return Observable.empty();
  }

  setPermission(permissions, component) {
    this._permissions[component] = this._permissions[component] || {};
    this._permissions[component].data = permissions;
    this._permissions[component].timemodified = new Date().getTime();
  }
  isValidPermissions(component) {
    if (this._permissions[component]) {
      let time = this._permissions[component].timemodified;
      let now = new Date().getTime();
      if (now - time > this.refreshPermissionTime) {
        return true;
      }
    }
    return false;
  }
}
