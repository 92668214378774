import { Pipe, PipeTransform } from '@angular/core';
import * as _moment from 'moment';

@Pipe({ name: 'formatter' })
export class Formatter implements PipeTransform {
  formaters = {
    cpf: (value: string) =>
      value.replace(/(^\d{3})(\d{3})(\d{3})/g, '$1.$2.$3-'),
    cnpj: (value: string) =>
      value.replace(/(^\d{2})(\d{3})(\d{3})(\d{4})/g, '$1.$2.$3/$4-'),
    phone: (value: string) => value.replace(/(^\d{2})(\d{4})/g, '($1) $2-'),
    date: (value: any) => {
      let dateobj = _moment(value);
      return dateobj.format('DD/MM/YYYY');
    },
    cep: (value: any) => value.replace(/(\d{5})(\d{3})/, '$1-$2'),
  };

  transform(value: string, format: string) {
    value = value || '';
    return format && this.formaters.hasOwnProperty(format) ? this.formaters[format](value) : value;
  }
}
