import { Parsers } from './../../../utils/parsers';
import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Masks } from '../../../utils/masks';

/**
 * Generated class for the CepComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'ngx-inputmask',
  templateUrl: 'inputmask.html',
})
export class InputmaskComponent extends FieldType {
  get type() {
    return this.to.type || 'text';
  }

  ngOnInit() {
    this.to.mask = Masks[this.to.mask];
    this.field.parsers = [Parsers[this.to.parser]];

    this.options.formState.activeParsers = {};
    this.options.formState.activeParsers[this.field.key] = this.field.parsers;
  }
}
