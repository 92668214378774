import { FieldType } from '@ngx-formly/core';
import { Component } from '@angular/core';

@Component({
  selector: 'ngx-checkbox',
  templateUrl: './checkbox.component.html',
})
export class CheckboxComponent extends FieldType {

  ngOnInit() {
    if (this.to.forceBoolean && !this.to.customValue) {
      this.formControl.setValue(this.formControl.value == "1");
    }

    if (this.to.customValue) {
      Object.defineProperty(this.formControl, 'oldSet', {
        value: this.formControl.setValue
      });

      this.formControl.setValue = (function (customValue) {
        return function (value: any, options?: object) {
          this.oldSet(value && customValue, options);
        };
      })(this.to.customValue);
    }
  }
}
