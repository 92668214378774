import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  Input,
  OnDestroy,
} from '@angular/core';
import { BaseResourceProvider } from '../../../../@core/data/providers/base-resource-provider';
import { FormControl } from '@angular/forms';
import { NgxPermissionsService } from 'ngx-permissions';
import { langc } from '../../../../helpers';
import { Observable } from 'rxjs';

@Component({
  selector: 'ngx-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnDestroy {
  @ViewChild('actionsDefaultTemplate') actionsDefaultTemplate: TemplateRef<any>;
  @ViewChild('cellTemplate') cellTemplate: TemplateRef<any>;

  @Input() provider: BaseResourceProvider;
  @Input() table: any;
  @Input() resource: string;
  @Input() actionTemplate: TemplateRef<any> = null;

  rows: Array<any>;
  columns: Array<any>;

  meta: any;

  page: number;
  search: string;
  otherParams: any = {};

  hasMoreItems: boolean;
  loading: boolean = true;

  searchControl = new FormControl();

  on = true;

  constructor(private permissionService: NgxPermissionsService) { }

  ngOnInit() {
    const permissions = [];

    this.searchItems();
    this.columns = [...this.table.columns];

    this.columns.forEach(item => {
      item.cellTemplate = this.cellTemplate;
    });

    for (const permission of this.table.permissions) {
      permissions.push(permission);
    }

    if (
      !this.table.permissions ||
      this.permissionService.hasPermission(permissions)
    ) {
      this.columns.push({
        name: 'Ações',
        prop: 'id',
        width: '300px',
        cellTemplate: this.actionTemplate || this.actionsDefaultTemplate,
      });
    }
  }

  ngOnDestroy() {
    this.on = false;
  }

  getList(response) {
    this.rows = response.data;
    this.rows.forEach((row, index) => {
      row.delete_message = langc(
        'core.' + this.resource + '_form_delete_message',
        1,
        row,
      );
    });
    this.meta = response.meta;
    this.loading = false;
  }

  fetch(f: (response) => any) {
    this.loading = true;
    return this.provider
      .list({ page: this.page, search: this.search, otherParams: this.otherParams })
      .takeWhile(() => this.on)
      .debounceTime(500)
      .subscribe(f, () => {
        this.loading = false;
      });
  }

  searchItems() {
    this.searchControl.valueChanges.debounceTime(1000).subscribe(() => {
      this.reset();
      this.fetch(this.getList);
    });
  }

  reset() {
    this.page = 1;
    this.hasMoreItems = true;
  }

  onSort(event) {
    const sort = event.sorts[0];
    const pattern = new RegExp('^_');
    this.otherParams = {
      orderBy: sort.prop.replace(pattern, ''),
      sortedBy: sort.dir,
    };
    this.fetch(this.getList);
  }

  setPage(event) {
    this.page = event.offset + 1;
    this.fetch(this.getList);
  }

  public delete(row) {
    this.provider
      .destroy(row.id)
      .toPromise()
      .then(() => {
        this.fetch(this.getList);
      });
  }
}
