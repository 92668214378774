import { langc } from './../../../helpers';
import { Component, OnInit, Input } from '@angular/core';
import { lang } from 'moment';
import { BaseResourceProvider } from '../../../@core/data/providers/base-resource-provider';

@Component({
  selector: 'table-actions',
  templateUrl: './table-actions.component.html',
  styleUrls: ['./table-actions.component.scss'],
})
export class TableActionsComponent implements OnInit {
  @Input() row: any;

  @Input() provider: BaseResourceProvider;
  @Input()
  actions: Array<{
    type;
    title;
    icon;
    permission?;
    link?;
    confirm?: (row: any) => {};
    swal?;
    wrap_params?;
    params?;
    method?;
  }>;

  _actions = [];

  constructor() { }

  ngOnInit() {
    this._actions = JSON.parse(JSON.stringify(this.actions));
    this._actions.forEach((item, index) => {
      item.confirm = this.actions[index].confirm;
    });
  }

  parseTitle(action, row) {
    return langc(action.title, 1, row);
  }

  parseSwal(action, row) {
    action.swal.title = langc(action.swal.title, 1, row);
    return action.swal;
  }
}
